import * as _ from "lodash";
import { IsolationRequest } from "./requests/isolation-request.model";
import { PDFRepositoryRequest } from "./requests/pdf-repository-request.model";
import { ChildProject, IEDEmbededRequest } from "../project.model";
import { IUser } from "../users.model";
import { ProjectService } from "src/app/services/project.service";
import { TextCorrectionRequest } from "./requests/text-correction-request.model";
import { PlanCorrectionRequest } from "./requests/plan-correction-request.model";
import { TextPlanCorrectionRequest } from "./requests/text-plan-correction-request.model";
import { FileRedeliveryRequest } from "./requests/file-redelivery.model";
import { NewEpsonRequest } from "./requests/new-epson.model";
import { PackshotRequest } from "./requests/packshot.model";
import { HeroImageRequest } from "./requests/hero-image.model";
import { NewAccountRequest } from "./requests/new-account.model";
import { BriefingRequest } from "./requests/briefing.model";
import { BaseModel, Id } from "../base.model";
import { FlowType, IProofReading, Proofreading } from "../proofreading.model";
import { IMember, Member } from "../members.model";
import { ProductionTexts } from "../production-texts.model";
import { IUploadedFile } from "src/app/services/file.service";
import { ICustomer } from "../customer.model";

export type RequestTypeAgregation =
    | IsolationRequest
    | PDFRepositoryRequest
    | TextCorrectionRequest
    | PlanCorrectionRequest
    | TextPlanCorrectionRequest
    | FileRedeliveryRequest
    | NewEpsonRequest
    | PackshotRequest
    | HeroImageRequest
    | NewAccountRequest
    | BriefingRequest;

export enum RequestStatus {
    INIT = "init",
    PENDING = "pending",
    PROJECT_LINKED = "project_linked",
    DELIVERED = "delivered",
    CLOSED = "closed",
}

export enum RequestType {
    NONE = "none",

    BRIEFING = "briefing",
    PDF_REPOSITORY = "pdf-repository",
    TEXT_CORRECTION = "text-correction",
    PLAN_CORRECTION = "plan-correction",
    TEXT_PLAN_CORRECTION = "text-plan-correction",

    // PROJET SANS RELECTURE
    // SERVICE HD
    VISUALS_ISOLATION = "visuals-isolation",
    PACKSHOT = "packshot",
    HERO_IMAGE = "hero-image",

    // HORS LV JUSTE RIVERFLOW 
    FILE_REDELIVERY = "file-redelivery",
    NEW_EPSON = "new-epson",

    // PLUS TARD
    // NEW_ACCOUNT = "new-account",
}

export interface IRequest {
    _id: Id;
    ref: string;
    flow: FlowType | null;
    name: string;
    project?: {
        ID: string,
        identifier: string;
    };
    type: RequestType;
    status: RequestStatus;
    customerID: string;
    customer?: ICustomer;
    contactID: string;
    contact?: IUser;
    date: Date;
    version: number;
    options: any;

    source_project?: ISourceProjectSubRequest;
    relecture?: IRelectureSubRequest;
    finalization?: IFinalizationSubRequest;

    project_attribution_date?: Date;

    get isEditable(): boolean;
    get isDeletable(): boolean;
    get isLinkable(): boolean;
    get createRVF(): boolean;
    isValid(key?: string): boolean;
}
export type FilePathDescriptor = { key: string; }[];
export class Request extends BaseModel implements IRequest {
    protected override __build: { [path: string]: new (data: any) => any; } = {
        'relecture.proofreadings': Proofreading,
        'relecture.members': Member,
    };

    ref: string;
    flow: FlowType | null = null;
    name: string;
    project?: {
        ID: string,
        identifier: string;
    };
    type: RequestType = RequestType.NONE;
    status: RequestStatus = RequestStatus.INIT;
    customerID: string;
    contactID: string;
    contact?: IUser;
    date: Date = new Date();
    version: number;
    options: any = {};

    // SUB MODULE REQUESTS
    source_project?: ISourceProjectSubRequest;
    relecture?: IRelectureSubRequest;
    project_attribution_date?: Date;

    finalization: IFinalizationSubRequest = {
        comment: '',
        responsability_agreement: false,
    };

    // CONDITIONAL FLAGS
    get isEditable() {
        return this.status === RequestStatus.INIT || this.status === RequestStatus.PENDING;
    }
    get isDeletable() {
        return this.status === RequestStatus.INIT || this.status === RequestStatus.PENDING;
    }
    get isLinkable() {
        return this.status === RequestStatus.PENDING && !this.project?.ID;
    }
    get createRVF() {
        return !!this.options?.rvf;
    }


    /**
     * CALLBACKS
     * used to specify different behaviors for the diffents request Types
     * Differ from the callback in the request model in the liveco Server
     */

    /**
     * Callback called during the project Creation phase (if the project is from an easydiadem request)
     * @param service
     * @param lifecycle
     */
    onProjectCreation(service: ProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                // SET EZD CORE VALUE AS IEmbbededRequest
                _.set(service.childData, 'custom.easydiadem', {
                    ..._.get(service.childData, 'custom.easydiadem') || {},
                    _id: this._id,
                    ref: this.ref,
                    type: this.type,
                } as IEDEmbededRequest);

                // Set request id_archive from projectFrom
                if (this.source_project?.identifier) _.set(service.childData, 'custom.general.id_archive', this.source_project?.identifier);
                // Set request codearticle from name
                _.set(service.childData, 'custom.general.id_codearticle_info', this.name);
                // Set customer member in project customer
                _.set(service.masterData, 'custom.general_info.customer', this.contact?.customer);
                // Refresh service to trigger all job subject subscribers
                service.refresh();
                break;
        }
    }

    constructor(data: any) {
        super(data);
        this.init(data);
    }

    public isValid(key?: string) {
        return !_.isEmpty(this.name);
    }

    /**
     * FLAGS && STATUS 
     *
     */

    is(type: RequestType) {
        return this.type === type;
    }

    hasStatus(status: RequestStatus) {
        return this.status === status;
    }

    getOption(path: string) {
        return _.get(this.options, path);
    }

    public toJSON(): _.Omit<any, "__build" | "__defaults" | "__stepper"> {
        return _.omit(this, ['__build', '__defaults', '__stepper']);
    }
}

// REQUESTS INTERFQCE TYPE
export interface IRelectureRequest extends IRequest {
    relecture: IRelectureSubRequest;
}
export interface IArchiveRequest extends IRequest {
    description: string;
    archive: IArchiveSubRequest;
}

/**
 * SUB MODULE REQUESTS
 */

export interface ISourceProjectSubRequest {
    identifier: string;
    data?: ChildProject;
}

export interface IRelectureSubRequest {
    proofreadings?: Proofreading[];
    proofreadingsID?: string[];
    members: Member[];
}

export interface IModificationSubRequest {
    info_validity: boolean;
    comment: string;
}

export interface ITextSubRequest {
    type: 'instruction' | 'production-texts' | 'file-txt';
    textsID?: string;
    file_txt?: IUploadedFile;
    comment?: string;
}

export interface IPlanSubRequest {
    file_plan?: IUploadedFile;
}

export interface IContactSubRequest {
    lastname: string;
    firstname: string;
    address: {
        street: string;
        cp: string;
        city: string;
    };
    tel: string;
    email: string;
}

export interface IArchiveSubRequest {
    source_sub_path: string;
    download_url?: string;
}

export interface IFinalizationSubRequest {
    comment: string;
    responsability_agreement?: boolean;
}  