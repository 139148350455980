import { O, PERIOD, T } from "@angular/cdk/keycodes";
import { shooting } from "../models/easydiadem/requests/packshot.model";
import { VERSION } from "lodash";

export const fr = {
    NO_BRANDS: "Aucune marque",
    BRANDS: "marques",
    RELECTURE_EXTERN: "Relecture",
    RELECTURE_INTERN: "Relecture interne",
    LAST_REVISION: "Dernière revision",
    ARCHIVED: "Archivé",
    PR_UPDATED: "Relecture mise à jour.",
    RETURN: "Retour",
    LANGUAGES: "Langues",
    CANCEL_FULLSCREEN: 'Réduire',
    INFORMATION: "Informations",
    ASSERT_INFORMATIONS: "Les informations ci-dessous sont-elles toujours exactes ?",
    COMMENTARY: "Commentaires",
    COMMENTARY_PLACEHOLDER: "Indiquez ici les changements à apporter",
    RELECTURE_MEMBERS: 'Membres pour relecture',
    TODO: "A verifier",
    DONE: "Fait",
    DOWNLOAD: "Télécharger",
    PDF_CAN_REUPLOAD: "Vous pouvez charger un nouveau fichier PDF",
    UPLOAD: "Charger",
    PDF_PLEASE_REUPLOAD: "Veuillez charger un nouveau fichier PDF",
    OLD_PROJECT: "Projet limité",
    REORDER: "Réordonner",
    NOT_ALLOWED_ON_ARCHIVED: "Action non autorisée sur un projet archivé",
    FORM: {
        "TEXT-CORRECTION": "Correction de texte",
        "PDF-REPOSITORY": "PDF",
        "FILE-REDELIVERY": "Relivraison de fichiers",
        HEADS: {
            PLAN: "Plan",
            CUSTOMER_BRIEF: 'Brief Client',
            COLOUR: "Couleur",
            CREA_FILES: 'Fichiers Créa',
            IMAGES_TO_RETOUCH: 'Images à retoucher',
            TECHNICAL_PLAN: 'Plan technique',
            TEXT_SHEET: 'Fiche texte',
            PRINTING_METHOD: 'Méthode d\'impression',
            COLORING: 'Mise en couleur',
            BRAND_CHART: 'Charte de marque',
            DEADLINE: 'Délai',
            VALIDATION_CYCLE: 'Cycle de validation',
            CLIENT_CONTACT: 'Contact client',
            ARCHIVE_EXISTING_REFERENCE: 'Archive référence existante',
            SOURCE_PROJECT: 'Archive concernée',
            PRINTER: 'Impression',
            SEQ_COLORS: 'Séquence d\'impression',
            TEXT: "Fiche textes",
            RELECTURE: 'Membres pour relecture',
            FINALIZATION: 'Finalisation',
            EPSON_CONTACT: 'Contact',

            NAME: "Informations",
            QUANTITY: "Quantité",
            NUM_JOB: "Numéro de job",
            FILES: "Fichier",
            FILE: "Fichier",
            DESCRIPTION: "Description",
            SHOOTING: "Prises de vue",
            REDELIVERYTYPE: "Type de relivraison",
        },
    },
    ASSESSMENT: {
        PENDING: "En attente",
        APPROVE: "Approuvé",
        REJECT: "Rejeté",
        IN_PROGRESS: "Att. approbation",
        READONLY: "Lecture seule",
        READONLY_PROOFSCOPE: "Consultation",
        PAUSED: "Cycle en pause"
    },
    STATE: 'État',
    APPROVAL_SENT: 'Approbation reçues',
    SEE_ALL: 'Tout voir',
    ADDRESS: {
        ADDRESS: "Adresse",
        LASTNAME: "Nom",
        FIRSTNAME: "Prénom",
        STREET: "Adresse (numéro et rue)",
        CITY: "Ville",
        CP: "Code postal",
        EMAIL: "Adresse e-mail",
        TEL: "Téléphone"
    },
    FEATURE_TO_COME: "Fonctionnalité(s) à venir",
    RECENT_PROJECTS: "Historique",
    LAST_10_ENTRIES: "10 dernières entrées",
    HI_DESCRIPTION: "Quels élements souhaitez-vous mettre en avant sur votre Hero Image?  (il sera livré aux formats jpeg, png - Basse déf et Haute déf) : ",
    CLOSED: "Clôturé",
    FULL_SCREEN: "Plein écran",
    NO_FULL_SCREEN: "Ecran normal",
    COMPARE_DOC_A: "Document A",
    COMPARE_DOC_B: "Document B",
    COMPARE_DOC: "Document",
    COMPARE_CURRENT: "(version actuelle)",
    CREATE_PROOFREADING: "Créer une relecture",
    SELECT_FLOW: "Sélectionnez un type de flux",
    PROOFREADING_MISSING: "La relecture désirée n'existe pas",
    FLOW: "Flux",
    EXE: "EXE",
    REPRO: "REPRO",
    CREA: "CREA",
    HD: "HD",
    EZD_EXE: "EZD - EXE",
    EZD_PDF_REPOSITORY: "EZD - PDF",
    INFORMATIONS: "Informations",
    READ_ONLY_APPROVER: "Approbateur en lecture seule",
    PRODUCTION_TEXTS: {
        ERROR_SAVING: "Vous n'avez pas la permission de sauvegarder les textes de production",
        CREATE_MODIFY: "Modifier / Créer fiche Texte",
        INSTRUCTIONS: "Ecrire les instructions",
        UPLOAD: "Dépôt de fiche textes",
        OPEN: "Ouvrir",
        NAME: "Fiche Textes",
        ADD: "Ajouter une fiche texte",
        ARE_YOU_SURE_ADD: "Voulez-vous ajouter une fiche texte ?",
        ARE_YOU_SURE_REMOVE: "Voulez-vous supprimer ce champ texte ?",
        MANAGE_LANG: "Langues",
        PREFILL: "Préremplir",
        ADD_FIELD: "Ajouter un champ",
        FIELD_NAME: "Nom du champ",
        LABEL: "Libellé",
        FACING: "Facing",
        BACK: "Dos + côtés",
        FIELD_PLACEHOLDER: "Entrez le nom du champ",
        LEGAL: "Textes légaux",
        MARKETING: "Textes marketing",
        TVN: "TVN",
        SAVED: "Fiche texte sauvegardée",
        REMOVED: "Champ texte supprimé",
        PREFILL_SELECT_REF: "Sélectionnez une référence",
        PREFILL_SELECT_REF_TEXT: "Cet action va remplacer le contenu de la fiche texte par celui de la référence sélectionnée. Voulez-vous continuer ?",
        PREFILL_SELECT_REF_PLACEHOLDER: "PXXXXXX",
    },
    TVN: {
        ADD_FIELD: "Ajouter un champ",
    },
    ERROR_FORM: " erreurs trouvé(s) dans le formulaire. Corrigez les avant de soumettre le formulaire.",
    COMPARE_VERSION: "Version",
    COMPARE_RELEC: "Relecture",
    COMPARE_RESET: "Réinitialiser la comparaison",
    INTERN_REVISIONS: "Révisions internes",
    CLIENT_REVISIONS: "Révisions client",
    INTERN_REVISIONS_INFO: "Interne",
    EXTERN_REVISIONS_INFO: "Externe",
    CLIENT_REVISIONS_INFO: "Révision",
    VERSION_REVISIONS_INFO: "Versions",
    NOT_AVAILABLE_YET: "Fonctionnalité à venir",
    MAIN_APPROVER: "Approbateur principal",
    INFO_APPROVER: "Informatif",
    OBSERVER_APPROVER: "Observateur",
    GROUP_APPROVER: "Groupe",
    GROUP_MEMBER: "Membre du groupe",
    SELECT_APPROBATION_TYPE: 'Sélectionner le type d\'approbation du groupe',
    MEMBER_ALREADY_ADDED: 'le(s) membre(s) : ${ name } ont déjà été ajoutés.',
    ONE_FOR_ALL: "Décision commune",
    INDIVIDUAL: "Décision individuelle",
    UPLOAD_FILE: "Charger un fichier",
    MEMBERS_STATUS: {
        NULL: "Pas d'approbation",
        PAUSED: "En pause",
        PENDING: "Pas d'approbation",
        ACCEPT: "Accepté",
        REJECT: "Rejeté",
        INPROGRESS: "En cours",
        READONLY: "Lecture seule",
        READONLYPROOFSCOPE: 'Consultation',
    },
    REQUEST_STATUS_UPDATE: "Mettre a jour le status de la requête",
    REQUEST_STATUS_UPDATE_CONFIRMATION: "Voulez-vous vraiment mettre à jour le status de la requête ?",
    REQUEST_STATUS_UPDATED: "Le status de la requête a été mis à jour.",
    ED: {
        EPSON: {
            QUANTITY: "Quantité d'Epson",
        },
        ALL: "Tous",
        EDIT: 'Editer la requête.',
        ALREADY_SENT: "Demande déjà envoyée.",
        ARCHIVE: "Indiquer l'archive concernée :",
        PROJECT_ARCHIVED: "Indiquer le projet concerné :",
        GENERATE_ARCHIVE: "Générer l'archive",
        REGENERATE_ARCHIVE: "Régénérer l'archive",
        PLEASE_CHOOSE_TEXTS_TYPE: "Veuillez choisir comment vous souhaitez nous indiquer les nouveaux textes :",
        ZIP_TXT_DOC: "Veuillez compiler l’ensemble de votre demande/fiche texte dans un seul document (PPT/Word/Excel)",
        ZIP_PLAN_DOC: "Veuillez compiler l’ensemble de votre demande dans un seul document (PDF)",
        INSTRUCTIONS: "Vous pouvez indiquer ici les instructions pour les textes",
        ARE_YOU_SURE_CLOSE: "Le demande sera archivée.",
        CLOSE: "Clôturer la demande",
        DASHBOARD: "Dashboard",
        MEMBERS_INDICATE: "Indiquez les membres qui reliront les référénces",
        ARE_YOU_SURE_DELETE: "Voulez-vous vraiment clore cette demande ?",
        REQUEST_DELETED: "La demande a été supprimée.",
        REQUEST_CREATE_ARCHIVE: "Créer une archive",
        REQUEST_CREATE_ARCHIVE_CONFIRMATION: {
            'HERO-IMAGE': "Une archive sera créée et envoyée à partir des éléments présents dans le sous-dossier '3. HD'.\n Vérifiez bien ces éléments avant de continuer.",
            'VISUALS-ISOLATION': "Une archive sera créée et envoyée à partir des éléments présents dans le sous-dossier '3. HD'.\n Vérifiez bien ces éléments avant de continuer.",
            'PACKSHOT': "Une archive sera créée et envoyée à partir des éléments présents dans le sous-dossier '3. HD'.\n Vérifiez bien ces éléments avant de continuer.",
            "FILE-REDELIVERY": "Une archive sera créée et envoyée à partir des éléments présents dans le sous-dossier 'z_Elements_divers/_LIVRAISON_[EXE ou REPRO]'.\n Vérifiez bien ces éléments avant de continuer.",
        },
        SHOOTING: "Prises de vue",
        FINISH: {
            FINISH: "Finalisation",
            COMM: "Vous pouvez indiquer ici des compléments d’information si vous le souhaitez",
            TXT: "J’ai compris que j’engage ma responsabilité quand aux éléments qui seront validés de ma part avant livraison des fichiers. \n Diadem réalise les corrections demandées, leurs validations et le process de relecture reposent entièrement sur le client.",
            CONTACT_TXT: "J’ai compris que cette prestation sera intégralement gérée via la plateforme Easy Diadem, sans relation directe avec vos contacts habituels chez Diadem. Pour toute demande de prestation jugée trop complexe, ou en cas de prise de contact autrement que par la plateforme Easy Diadem, Diadem se réserve le droit de réaliser un devis additionnel en fonction de la complexité du travail demandé"
        },
        FILES: {
            UPLOAD_SUCCESS: "Les fichiers ont bien été chargés.",
            UPLOAD_FAILED: "Une erreur est survenue lors du chargement des fichiers."
        },
        REQUEST: {
            NAME: 'Requête EZD',
            CREATED: "Votre requête a bien été crée.",
            SAVED: "Votre forumlaire a été enregistré."
        },
        STATUS: {
            INIT: "En cours de création",
            PENDING: "En attente de traitement",
            PROJECT_LINKED: "Pris en charge",
            WAITING_PRODUCTION: "En attente de production",
            PRODUCTION: "En cours de production",
            RELECTURE_IN_PROGRESS: "Relecture en cours",
            VALIDATED: "Validé",
            UNVALIDATED: "Non validé",
            DELIVERING: "En cours de livraison",
            DELIVERED: "Livré",
            CLOSED: "Requête cloturée."
        },
        VISUAL_ISOLATION_DESCRIPTION: "Merci de lister les éléments à isoler (ou charger un fichier)",
        ACTION: {
            CLOSE: "Clôturer",
            DELIVERY: "Livrer",
        },
        BRIEFING: {
            TITLE: "Fiche de Brief",
            TYPE_DATAS: "Saisissez vos données",
            TYPE_DATE: "Sélectionnez une date avec le calendrier",
            MANDATORY: "Obligatoire",
            REQUIRED_FIELD: "Champ obligatoire",
            OPTIONAL_FIELD: "Champ facultatif",
            CUSTOMER_BRIEF: 'Brief Client',
            COLOUR: "Couleur",
            CREA_FILES: 'Fichiers Créa',
            IMAGES_TO_RETOUCH: 'Images à retoucher',
            ARCHIVE_EXISTING_REFERENCE: 'Archive référence existante',
            NUM_JOB_QUESTION: 'Quel est le numéro de job ?',
            NUM_JOB_LABEL: "Numéro de job",
            NUM_JOB_LABEL_PLACEHOLDER: "Entrez le numéro de job",
            TECHNICAL_PLAN: 'Plan technique',
            TECHNICAL_PLAN_CONTENT: {
                TYPE: "Type de demande",
                NEW_REF_SHORT: "Nouvelle(s) référence(s)",
                UPDATE_REF_SHORT: "Mise à jour d'une (ou plusieurs) référence(s)",
                NO_INFO_SHORT: "Pas encore d'information",
                NEW_REF_TITLE: "Veuillez déposer le/les fichier(s) du plan technique ici",
                NEW_REF_SUBTITLE: "Précision : format PDF (ou .ai, .dxf)",
                NEW_REF_TITLE_2: "Ou nous indiquer si d'autres références ont le même plan",
                UPDATE_REF_TITLE: "Le/les plan(s) a-t-il/ont-ils évolué(s) par rapport à l'archive ?",
                OPTION_YES: "Oui",
                OPTION_NO: "Non",
                UPDATE_REF_SUBTITLE: "Veuillez déposer le/les nouveau(x) fichier(s) du plan technique ici",
                NO_INFO_TITLE: "Pas encore d'information à ce sujet"
            },
            TEXT_SHEET: 'Fiche texte',
            TEXT_SHEET_CONTENT: {
                TITLE: "Où récupérer les textes ?",
                OPTION_ARCHIVE: "Récupérer les textes sur l'archive (Veuillez remplir le champ ci-dessous)",
                OPTION_FILE: "Dépôt d'une fiche texte",
                OPTION_ONLINE: "Création d'une fiche texte en ligne",
                ARCHIVE_TITLE: "Pour une archive, veuillez indiquer la/les archive(s) concernée(s)",
                ARCHIVE_LABEL: "Nom ou numéro des archives",
                FILE_TITLE: "Pour un dépôt, veuillez déposer ici la/les fiche(s) texte(s)",
                FILE_SUBTITLE: "Précision : Format PDF/Word/Excel/PowerPoint"
            },
            PRINTING_METHOD: 'Méthode d\'impression & cahier des charges imprimeur',
            PRINTING_METHOD_SHORT: 'Méthode d\'impression',
            PRINTING_METHOD_CONTENT: {
                PRINTING_METHOD_LABEL: "Si vous connaissez la/les méthode(s) d'impression, veuillez l'indiquer",
                PRINTING_METHOD_SHORT: "Méthode(s) d'impression",
                PRINTER_NAME_LABEL: "Si vous connaissez le nom de l'imprimeur, veuillez l'indiquer",
                PRINTER_NAME_SHORT: "Nom de l'imprimeur",
                SPECIFICATIONS_LABEL: "Si vous disposez du cahier des charges de l'imprimeur, veuillez le déposer ici"
            },
            COLORING: 'Mise en couleur',
            COLORING_CONTENT: {
                TYPE: "Quelle est la mise en couleur de votre/vos référence(s) ?",
                NEW_REF_SHORT: "Nouvelle(s) référence(s)",
                UPDATE_REF_SHORT: "Mise à jour d'une (ou plusieurs) référence(s)",
                NO_INFO_SHORT: "Pas encore d'information",
                NEW_REF_TITLE: "Veuillez nous indiquer la mise en couleur déterminée (ou le nombre maximum de couleurs autorisées pour l'impression)",
                UPDATE_REF_TITLE: "La/les mise(s) en couleurs a-t-elle/ont-elles évoluée(s) par rapport à l'archive ?",
                OPTION_YES: "Oui",
                OPTION_NO: "Non",
                UPDATE_REF_SUBTITLE: "Veuillez nous indiquer la mise en couleur déterminée (ou le nombre maximum de couleurs autorisées pour l'impression)",
                NO_INFO_TITLE: "Pas encore d'information à ce sujet"
            },
            BRAND_CHART: 'Charte de marque',
            BRAND_CHART_TITLE: "Si vous disposez d'une charte de marque, veuillez la déposer ici ",
            BRAND_CHART_SUBTITLE: "Précision : Ce n'est pas nécessaire s'il n'y en a pas ou si Diadem en dispose déjà d'une",
            DEADLINE: 'Délai',
            DEADLINE_TITLE: "Veuillez indiquer le délai de livraison des fichiers d'Exécution",
            VALIDATION_CYCLE: 'Cycle de validation',
            VALIDATION_CYCLE_TITLE: "Si vous utilisez notre plateforme de relecture Liveco, veuillez nous lister les personnes à inclure dans le cycle de validation",
            CUSTOMER_CONTACT: 'Contact client',
            CUSTOMER_CONTACT_TITLE: 'Veuillez nous indiquer qui est le contact chez le client',
            SUBJECT_TITLE: "Brief Client",
            SUBJECT_LABEL: "Quel est l'objet du travail demandé ?",
            OTHER_TITLE: "Veuillez préciser :",
            OTHER_LABEL: "Travail à effectuer",
            OTHER_SUBJECT_PLACEHOLDER: "Décrivez le travail",
            BRIEF_TITLE: "Préparation de brief",
            BRIEF_LABEL: "Si vous avez préparé un brief, veuillez le déposer ici",
            COMMENT: "Informations supplémentaires si besoin",
            COMMENT_PLACEHOLDER: "Ajoutez un commentaire",
            REMARK: "Remarques",
            CREA_FILES_TITLE: "S'il s'agit d'une ou plusieurs nouvelles références, veuillez déposer ici les fichiers sources créatifs validés",
            CREA_FILES_SUBTITLE: "Précision : Assemblage des fichiers sources Illustrator non vectorisés avec textes vivants",
            IMAGES_TO_RETOUCH_TITLE: "S'il y a des nouvelles images à retoucher, veuillez déposer ici les fichiers sources (Les achats d’art ou les shoots)",
            IMAGES_TO_RETOUCH_SUBTITLE: "Précision : Images Photoshop sources non aplaties, traitées en CMJN.",
            ARCHIVE_REF_TITLE: "Si vous disposez d'archives pour votre (ou vos) référence(s), veuillez déposer les fichiers existants ici",
            ARCHIVE_REF_SUBTITLE: "Précision : De préférence les fichiers sources d'Exécution : Illustrator non vectorisé avec textes vivants, PDF, et fichiers Photoshop pour les images. Au minimum un PDF.",
            SUBJECTS: {
                UPDATE: "Mise à jour d'une référence existante",
                UPDATE_MULTIPLE: "Mise à jour de plusieurs références existantes",
                NEW_REF: "Exécution d'une nouvelle référence",
                NEW_MULTIPLE: "Exécution de plusieurs nouvelles références",
                LOGO_EXE: "Exécution de logo",
                OTHER: "Autres",
                EXE_ONLY: "Exécution seule",
                EXE_AND_RETOUCH: "Exécution et Retouche d'image",
                RETOUCH_ONLY: "Retouche d'image seule",
                FILE_PREPARATION: "Préparation de fichiers pour maquettes",
                ISOLATION: "Isolations (pictos, logos, visuels...)",
            },
            NAME_QUESTION: "Quel est le nom du projet ou de la référence à traiter?",
        },
        WHAT_CAN_WE_DO: "Que pouvons-nous faire pour vous aujourd'hui ?",
        TASKS: {
            PACKSHOT: "Réalisation d’un packshot",
            HERO_IMAGE: "Réalisation d’une Hero image",
            "HERO-IMAGE": "Réalisation d’une Hero image",
            "NEW-ACCOUNT": "Nouveau compte",
            BRIEFING: "Fiche de Brief Client",
            NEW_ACCOUNT: "Nouveau compte",
            NEW_EPSON: "Nouvelle Epson",
            "NEW-EPSON": "Nouvelle Epson",
            VISUALS_ISOLATION: "Isolation de visuel",
            "VISUALS-ISOLATION": "Isolation de visuel",
            FILE_REDELIVERY: "Relivraison de fichiers",
            "FILE-REDELIVERY": "Relivraison de fichiers",
            TEXT_CORRECTION: "Correction de texte simple",
            "TEXT-CORRECTION": "Correction de texte simple",
            "PLAN-CORRECTION": "Correction de plan (sans correction de textes)",
            PLAN_CORRECTION: "Correction de plan (sans correction de textes)",
            "TEXT-PLAN-CORRECTION": "Correction de texte et plan",
            TEXT_PLAN_CORRECTION: "Correction de texte et plan",
            PDF_REPOSITORY: "Dépôt de PDF",
            "PDF-REPOSITORY": "Dépôt de PDF",
            NONE: 'Aucune',
        },
        "PDF-REPOSITORY-TITLE": "Dépôt de PDF",
        "SELECTION_SOURCE_PROJECT_READONLY": "Précision : ....",
        "PDF-REPOSITORY": "Dépôt de PDF",
        "LINK-PROJECT": "Relier à un projet existant",
        WHAT_DO_YOU_WANT: "Merci de nous préciser votre demande *",
        WHICH_PROJECT: "Sur quel projet voulez-vous isoler un visuel ?",
        SEARCH_PROJECT: "Rechercher un projet...",
        SEND_DEMAND: "Envoyer la demande",
        SAVE_DEMAND: "Sauvegarder les modifications",
        LINK_PROJECT: "Relier la demande",
        LINK_USER: "Créer compte",
        PLAN_UPLOAD: "Dépôt de plan",
        OTHER_TYPE_REQUEST: "Autre type de demande",
        NEW_REQUEST: "Création d'une nouvelle demande",
        EDIT_REQUEST: "Edition de la demande",
        REQUEST_TYPE: "Type de requête",
        YOUR_DEMAND: "Votre demande",
        REQUEST_CREATE_PLACEHOLDER: "Séléctionnez un type de demande pour afficher puis remplir les champs correspondants",
        HAS_BEEN_TAKEN_INTO_CONSIDERATION: "a bien été prise en compte",
        AN_ERROR_OCCURED: "Une erreur est survenue",
        CLICK_HERE_TO_DROP_PDF: "Cliquez ici pour déposer votre PDF",
        CLICK_HERE_TO_DROP_FILE: "Veuillez cliquer ou déposer votre fichier ici",
        CLICK_HERE_TO_DROP_FILES: "Veuillez cliquer ou déposer vos fichiers ici",
        MAX: "Maximum",
        FILES_MIN: "fichiers",
        ALLOWED_FORMATS: "formats autorisés",
        FILE_DOWNLOAD: "Fichier téléchargé",
        PROJECT_LABEL: "Nommez votre projet",
        PROJECT_LABEL_PLACEHOLDER: "Nom du projet",
        MEMBERS: "Membres",
        ADD_MEMBER: "Ajouter un membre",
        STEP: {
            QUESTION: "Les informations ci-dessous sont-elles toujours exactes ?",
            QUESTION_PLAN: "En dehors du plan, les informations ci-dessous sont-elles toujours exactes ?",
            MEMBERS_TXT: "Indiquer les contacts qui reliront la référénces après corrections",
            COMM: "Quels sont les changements à apporter ?",
            PROOFREADING: "Modifier/Créer fiche texte",
            INSTRUCTIONS: "Écrire instructions",
            UPLOAD: "Dépôt de fiche textes",
            TXT: "Veuillez choisir comment vous souhaitez nous indiquer les nouveaux textes :",
            PLAN: "Veuillez choisir comment vous souhaitez nous indiquer le nouveau plan :"
        },
        TYPES: {
            "HERO-IMAGE": "Héro Image",
            "VISUALS-ISOLATION": "Isolation de visuel",
            "PDF-REPOSITORY": "Dépôt de PDF",
            "TEXT-CORRECTION": "Correction de texte",
            "PLAN-CORRECTION": "Correction de plan",
            "TEXT-PLAN-CORRECTION": "Correction de texte et plan",
            "NEW-EPSON": "Nouvelle Epson",
            "PACKSHOT": "Réalisation d’un packshot",
            "FILE-REDELIVERY": "Relivraison de fichiers",
            "NEW-ACCOUNT": "Nouveau compte",
            "BRIEFING": "Fiche de Brief Client",
        },
        DOWNLOAD: "Télécharger les éléments",
        USERNAME_EXIST: "Ce nom est déjà utilisé. Veuillez en choisir un autre",
        EMAIL_EXIST: "Cet email est déjà utilisé. Veuillez en choisir un autre",
        STEPPER: {
            INIT: {
                TITLE: "Commande en attente d'information",
                DESC: "Veuillez saisir les élements manquants"
            },
            PENDING: {
                TITLE: "Commande prise en compte",
                DESC: "Requête easyDiadem reçue"
            },
            PROJECT_LINKED: {
                TITLE: "En cours de traitement",
                DESC: "Requête validée"
            },
            WAITING_PRODUCTION: {
                TITLE: "Attente de production",
                DESC: "Date à définir",
                DESC_DATE: "Prévu le %%DATE%%"
            },
            PRODUCTION: {
                TITLE: "En production",
                DESC: "Requête traitée"
            },
            RELECTURE_IN_PROGRESS: {
                TITLE: "En relecture client",
                DESC: ""
            },
            DELIVERING: {
                TITLE: "Préparation livraison",
                DESC: "Préparation des fichiers"
            },
            DELIVERED: {
                TITLE: "Livré",
                DESC: "Fichiers livrés"
            },
            CLOSED: {
                TITLE: "Traitement terminé",
                DESC: "Le projet a été mené a terme"
            }
        }
    },
    QUANTITY: "Quantité",
    OLD_REF: "Ancienne référence",
    NEW_PROJECT: "Nouveau projet créé",
    PROOFREADING: "Relecture",
    NEW_PROOFREADING: "Nouvelle relecture",
    ADD_FIELD: "Champ personnalisé",
    ADRESS: "Adresse",
    ALERT: {
        MODIF_DOC: "Attention, vous ne pourrez plus revenir sur le document"
    },
    NO_PROOFREADING: "Pas de relecture",
    APPROVAL: {
        APPROVED: "Votre document a été approuvé",
        REJECT: "Votre document a été rejeté",
        TITLE: "Approbation"
    },
    APPROVE: {
        REJECT: "Rejeter",
        VALID: "Approuver"
    },
    APPROVERS: {
        DIALOG: {
            TEXT: "Attention, vous ne pourrez plus revenir sur le document",
            TITLE: "Envoyer vos commentaires"
        }
    },
    BACK: "Dos + côtés",
    CANCEL: "Annuler",
    CITY: "Ville",
    CODES: {
        ADRESS: "Adresse",
        CODE: "Code",
        CODES: "Gencod / QR code",
        COLOR: "Couleur",
        CORRECT: "Correction",
        DATAMATRIX: "Datamatrix",
        NAMEMACHINE: "Nom du cahier des charges",
        ECOLOR: "Couleur",
        GENCOD: "Gencod",
        GENCODTAILLE: "Taille mini",
        QRCODE: "QR CODE",
        RLB: "RLB",
        SENS: "Sens",
        TAILLE: "Modules / Tailles mini",
        TYPE: "Type",
        NAMESUPPORT: "Nom du support"
    },
    COLORS: {
        MANAGE: "Gérer les couleurs",
        COLOR: "Couleur",
        COLORS: "Mise en couleurs",
        COMM: "Spécificités imprimeur",
        COLORMAX: "Nombre de couleur maximum",
        MECREEL: "Nombre de couleur utilisées",
        PRINTCOLOR1: "Couleur 1",
        PRINTCOLOR10: "Couleur 10",
        PRINTCOLOR2: "Couleur 2",
        PRINTCOLOR3: "Couleur 3",
        PRINTCOLOR4: "Couleur 4",
        PRINTCOLOR5: "Couleur 5",
        PRINTCOLOR6: "Couleur 6",
        PRINTCOLOR7: "Couleur 7",
        PRINTCOLOR8: "Couleur 8",
        PRINTCOLOR9: "Couleur 9",
        PRINTFINITION1: "Finition 1",
        PRINTFINITION10: "Finition 10",
        PRINTFINITION2: "Finition 2",
        PRINTFINITION3: "Finition 3",
        PRINTFINITION4: "Finition 4",
        PRINTFINITION5: "Finition 5",
        PRINTFINITION6: "Finition 6",
        PRINTFINITION7: "Finition 7",
        PRINTFINITION8: "Finition 8",
        PRINTFINITION9: "Finition 9",
        PRINTFINITIONS: "Finitions",
        PRINTSEQ: "Séquence impression",
        REFS: "Références",
        SAMECOLORS: "Couleur communes",
        VALIDATORS: {
            NUMERIC: "Veuillez saisir un nombre"
        }
    },
    COLORBOOKS: {
        COLORBOOKS: "Nuancier (couleurs)",
        FINISHCOLORS: "Nuancier (finitions)",
        VALIDATORS: {
            COLORS: "Veuillez saisir un nom de couleur",
            CATEGORIES: "Veuillez sélectionner une catégorie"
        },
        CATEGORIES: "Catégories de couleur",
        COLORS: "Couleurs à ajouter",
        PLACEHOLDER: "PANTONE 100 C, PANTONE 101 C, PANTONE 102 C, PANTONE 103 C, PANTONE 104 C, PANTONE 105 C, PANTONE 106 C...",
        UPDATE: "La palette de couleurs a été mis à jour",
        CREATE: "La palette de couleurs a été créée",
        DIALOG: {
            EXIST: "Les couleurs suivantes existent déjà dans cette catégorie (elles ne seront pas créées)"
        }
    },
    COMM: "Commentaires",
    COMM_SUBMIT: "Commentaires sauvegardés",
    CONSTRAINTS: "Contraintes techniques",
    CONTACT: "Contact",
    CREATE: "Créer",
    CREATE_CHILD: "+ projet enfant",
    CREATE_DIALOG: {
        TEXT: "Envoyer au client ?",
        TITLE: "Fiche texte"
    },
    CREATE_MASTER: "+ projet parent",
    CUSTOM_FIELDS: "Champs personnalisés",
    CUSTOMER: {
        SEARCH: "Rechercher un client..."
    },
    MEMBERS: "Membres",
    CONTROL: "Contrôle",
    DASHBOARD: {
        BRIEF: "Fiche de brief",
        SPLITVIEW: "Relecture client",
        SPLITVIEW_INTERN: "Relecture interne",
        FOLDER: "Ouvrir le dossier",
        PACKZ: "Ouvrir avec PacKZ",
        UPDATE_JOB: "Editer le projet",
        HISTORY: "Historique des relectures",
        CONTROL_INTERN: "Relecture interne",
        COMPARE: "Comparer des versions",
        PREVIEW: "Prévisualiser",
        SET_READY: 'Le job est prêt',
        REFRESH: 'Rafraîchir la vue',
        FULLSCREEN: 'Plein écran',
    },
    STATUS: "Statut",
    DESCRIPTION: "DESCRIPTION",
    DIALOG: {
        APPROVE: {
            REJECT: "Rejeter",
            TEXT: "",
            TITLE: "Approbation du document",
            VALID: "Approuver"
        },
        ASSESSMENT: {
            TEXT: "",
            TITLE: "Approbation du document",
            VALID: "Approuver",
            APPROBATION: "Approbation",
            REJECT: "Rejet",
            APPROBATION_TXT: "Confirmez-vous l’approbation du document?",
            REJECT_TXT: "Confirmez-vous le rejet du document?"
        },
        PAUSE: {
            TITLE: "Pause",
            TEXT: "Mettre en pause la relecture en cours"
        },
        RESTART: {
            TITLE: "Redémarrer le cycle",
            TEXT: "Redémarrer la relecture en cours"
        }
    },
    DOCUMENT_READONLY: "Lecture seule",
    DOCUMENT_READONLY_PROOFSCOPE: "Consultation",
    READONLYPROOFSCOPE: "Consultation",
    READONLY: "Lecture seule",
    PENDING: "En attente",
    DOCUMENT_PENDING: "En attente",
    DOCUMENT_APPROVE: "Document approuvé",
    ACCEPT: "Document approuvé",
    DOCUMENT_REJECT: "Document rejeté",
    REJECT: "Document rejeté",
    "INPROGRESS-R": "En cours de traitement",
    INPROGRESS: "En cours de traitement",
    EMAIL: "Email",
    ERROR_CLIENTONLY_ATTRIBUTES_NAME: "Un Client ne peut pas etre membre de DIADEM",
    ERROR_MISSING_ATTRIBUTES_NAME: "Veuillez saisir des attributs",
    ERROR_MISSING_MARQUE_FOR_CUSOMER: "Veuillez saisir une marque pour un Client",
    ERROR: {
        NODATA: "Impossible de récupérer les données",
        NOREVISIONS: "Pas de relecture pour ce projet",
        NOSUBMIT: "Impossible de soumettre les données",
        NO_EDIT_MASTER: "Impossible de modifier les données d'un projet parent",
        ONLYCPPCAN: "Seuls les CPP peuvent accéder à cette page",
        ONLYDIADEMCAN: "Seuls les membres de diadem peuvent acceder a cette page",
        MUST_BE_IN_LIST: "L'entrée selectionnée doit être dans la liste"
    },
    NEW_PLAN: "Nouveau plan",
    FACING: "Facing",
    FIELD_NAME: "Nom du champ",
    FILETMINNEG: "Filet minimum négatif",
    FILETMINPOS: "Filet minimum positif",
    GO_TO_HOME: "Menu",
    ERRORS: {
        QUANTITY: "Veuillez saisir une quantité valide 1 - 5",
        REQUIRED_EMAIL: "Veuillez saisir une adresse email valide",
        TEL: "Veuillez saisir un numéro de téléphone valide",
    },
    HOME: {
        SPLIT_VIEW: "Relecture",
        SPLIT_VIEW_INTERN: "Relecture interne",
        ARCHIVE: "Archiver",
        ARCHIVED: "Archivé",
        COLORBOOKS: "Nuancier",
        DISARCHIVE: "Désarchiver",
        OPEN_FOLDER: "Ouvrir le dossier",
        BRIEF: "Fiche de brief",
        CREATE: "Fiche textes",
        CREATE_CHILD: "Projet enfant",
        CREATE_MASTER: "Projet parent",
        CREATE_PRINTER: "Créer un imprimeur",
        CREATE_PROJECT: "Créer un projet",
        CREATE_USER: "Ajouter un utilisateur",
        EDIT_PROJECT: "Éditer un projet",
        FORM: "Fiche textes",
        KIOSK: "Cloudflow",
        MENU: "Menu",
        PRINTER: "Imprimeur",
        HOME: "Accueil",
        PROJECT: "Projet",
        SPLITVIEW: "Relecture client",
        TABLE_PROJECT: "Projets",
        TABLE_PRINTER: "Imprimeurs",
        TABLE_USER: "Utilisateurs",
        UPDATE: "Éditer une fiche textes",
        USERS: "Utilisateurs",
        ACCOUNT: "Mon compte"
    },
    INFO: "Informations générales",
    MINTXTNEG: "Texte minimum négatif",
    MINTXTPOS: "Texte minimum positif",
    NAME: "Nom",
    NOPERMISSION: {
        TEXTAPPRO: "Textes envoyés aux approbateurs",
        TEXTOK: "Textes sont déjà OK",
        TITLE: "Pas de permission",
        TITLE_TEXTOK: "Pas de permission"
    },
    NOREF: "Pas de référence disponible",
    NORESULT: "Aucun résultat",
    NUTRITABLE: {
        AR_PORTION: "% AR / portion",
        FOR_100: "100",
        FOR_100_UNIT: "g/ml",
        FOR_PORTION: "Une portion de",
        UNIT_G: "(g)",
        UNIT_KCAL: "(Kcal)",
        UNIT_KJ: "(kJ)",
        VALEUR_MOYENNES: "Valeurs moyennes pour :"
    },
    OK: "Ok",
    ORIENTATION: "Orientation",
    PAYS: "Pays",
    PC_EDIT_SUCESS: "Vos modifications ont été enregistrées",
    POSTALCODE: "Code postal",
    PREFILL_JOB_CHILD_REFERENCE: "Pré-remplir les champs du projet enfant",
    UPDATE_DATE: "Date de Maj",
    PAUSE: "Pause",
    PASSWORD: "Mot de passe",
    CONNECTION: "Connexion",
    PRINTER: {
        PRINTING_METHOD: "Méthode d'impression",
        ADD_CONTACT: "Ajouter un contact",
        PREFILL_PRINTER: "Pré-remplir les champs imprimeur",
        SELECT_PRINTER: "Sélectionner un imprimeur",
        ARE_YOU_SURE_DELETE_PRINTER_TEXT: "L'imprimeur sera supprimé",
        NEW_PRINTER: "Nouvel imprimeur",
        CREATE_PRINTER: "Créer un nouvel imprimeur",
        COLLAGE: "Zone Collage",
        COLORDEBDEV: "Débord Couleur - développement",
        COLORDEBLAIZE: "Débord Couleur - laize",
        CREATE: "Créer imprimeur",
        EDIT_SUCESS: "Vos modifications ont été enregistrées",
        FILESEND: "Livraison Fichiers",
        MACHINE: "Machine",
        PRINT: "Impression",
        PRINTER: "Imprimeur",
        PRINTERS: "Imprimeurs",
        SENSIMPRESSION: "Sens d'impression",
        PROCESS: "Procédé d'impression",
        REPRO: {
            CONSTRAINTS: "Contraintes",
            CHROMIE: {
                CHROMIE: "Chromie",
                CIBLE: "Cible couleur fournie",
                ENCR: "Encrage maximum",
                PTMAX: "Point maxi",
                PTMIN: "Point mini",
                REPROPROFIL: "Profil",
                TRAM: "Trame",
                STEPREUVAGE: "Standard épreuvage"
            },
            EPREUVAGE: "Épreuvage",
            FILE1: "Fichiers à fournir",
            FILE2: "PSD",
            OPERATOR: "Infographiste REPRO",
            REPRO: "REPRO",
            RETRAIT: {
                BLANC: "Retrait Blanc",
                COULEUR: "Retrait Couleur",
                VERNIS: "Retrait Vernis"
            },
            TRAP: "Trapping",
            TRAP_RETRAIT: "Trapping et retrait"
        },
        SECUCOUPE: "Sécurité Coupe",
        SECURAIN: "Sécurité Raineur",
        SEND: "Livraison Fichiers",
        SPECIFICATION: "Emplacement cahier des charges",
        SPOT: "Lecture Spot ",
        SUPPORT: "Support"
    },
    FINISHBOOKS: {
        COLORS: "Veuillez saisir un nom de finition",
        PLACEHOLDER: "VERNIS, VERNIS MAT, VERNIS BRILLANT..."
    },
    HISTORY: "Historique",
    INTERN_HISTORY: {
        NONE: "Création",
        "CHECK-AUTO-CONTROL": "Auto-contrôle",
        READY: "Prêt",
        "CHECK-QUALITY": "Contrôle qualité",
        "CHECK-REPRO": "Contrôle repro",
        "CHECK-PROD": "Contrôle graphique",
        'PROD-INTERN-REJECTED': "Auto contrôle rejeté",
        'CPP-INTERN-REJECTED': "Contrôle CPP rejeté",
        "CHECK-CPP": "Contrôle CPP",
        "WAITING-AUTO-CONTROL": "Modifications (auto-contrôle)",
        "WAITING-CHECK-CPP": "Modifications (Ctrl CPP)",
        "INTERN-FINISHED": "Contrôle CPP validé",
        "INTERN-FINISHED-OPERATOR": "Contrôle Opé. validé",
        CONTROL_APPROVED: "Contrôle validé",
        CONTROL_REJECTED: "Contrôle non validé",
        WIP: "En production",
        'CONTROL-VALIDATED': "Contrôle validé",
        'CONTROL-FAILED': "Contrôle refusé",
        "GENERATING": "Génération",
        "PR-CLIENT-INPROGRESS": "Envoyé au client"
    },
    EXTERN: {
        'SEND-PR-CLIENT': "Vérification des textes par le client",
        'SEND-PR-CPP': "Vérification des textes par le chef de projet",
        'IN-PROGRESS': "En cours d'approbation",
        "REJECTED": "Document rejeté par le chef de projet",
        "APPROVED": "Document approuvé par le chef de projet",
        'FEEDBACK-INFO': "Document revu par les approbateurs info.",
        'FEEDBACK-CLIENT': "Document revu par l'approbateur document.",
        "PAUSED": "Relecture mise en pause.",
        "DELIVERED": "Livré",
        "POST-VALIDATION": "Post-Validation",
        "INIT": "Attente lancement",
        'IN-PRODUCTION': "En production",
        RESTART: "Redémarrer",
        WAIT_YOUR_TURN: 'Veuillez attendre votre tour pour procéder à l\'approbation, l\'approbation en est à l\'etape : ',
        BTN: {
            'IN-PRODUCTION': "Envoyer en production",
            'SEND-PR-CLIENT': "Envoyer au client",
            'SEND-PR-CPP': "Envoyer au chef de projet",
            'IN-PROGRESS': "En cours d'approbation",
            "REJECTED": "Rejeter",
            "APPROVED": "Approuver",
            'FEEDBACK-INFO': "Document revu par les approbateurs info.",
            'FEEDBACK-CLIENT': "Document revu par l'approbateur document.",
            "PAUSED": "Pause",
            "RESTART": "Redémarrer",
            "DELIVERED": "Livraison",
            "POST-VALIDATION": "Post-Validation",
            "INIT": "Attente lancement",
        },
        DASHBOARD: {
            'NULL': "Pas de relecture interne",
            'SEND-PR-CLIENT': "Relec. txt. - Client",
            'SEND-PR-CPP': "Relec. txt. - CPP",
            "INIT": "Att. lancement",
            'IN-PROGRESS': "Att. approbation",
            "REJECTED": "Rejeté",
            "APPROVED": "Accepté",
            "PAUSED": "En pause",
            'FEEDBACK-INFO': "Appro. info",
            'FEEDBACK-CLIENT': "Appro. client",
            'DELIVERED': 'Livré',
            "POST-VALIDATION": "Post-Validation",
            'IN-PRODUCTION': "En production",
        },
        DIALOG: {
            'IN-PRODUCTION': "Voulez-vous envoyer la relecture en production ?",
            'SEND-PR-CLIENT': "Envoyer la relecture au client ?",
            'SEND-PR-CPP': "Valider la relecture aupres du chef de projet",
            'IN-PROGRESS': "En cours d'approbation",
            "REJECTED": "Voulez-vous rejeter le document ?",
            "APPROVED": "Voulez-vous approver le document ?",
            'FEEDBACK-INFO': "Voulez-vous soumettre votre approbation ?",
            'FEEDBACK-CLIENT': "Voulez-vous soumettre votre approbation ?",
            "PAUSED": "Mettre la relecture en pause ?",
            "DELIVERED": "Voulez-vous livrer le job ?",
            "POST-VALIDATION": "Voulez-vous remettre le job en production ?",
            RESTART: "Voulez-vous redémarrer l'approbation ?",
        }
    },
    INTERN: {
        SELECT_FLOW: {
            TITLE: "Selection du Flux",
            DESC: "Veuillez selectionner le flux de travail",
        },
        WARN_PROD: {
            TITLE: "Avertissement !",
            RELECTURE: "Vous êtes en relecture : ",
            DESC: "Réalisez votre pack et remplissez les informations dans l'onglet cartouche de votre job dans Liveco 4.0",
        },
        TODO: {
            EXE: "EXE",
            REPRO: "REPRO",
            EZD_EXE: "EZD - EXE",
            INFO_CARTOUCHE: "Infos Cartouche",
            REPRO_FILES: "Fichier REPRO",
            EXE_FILES: "Fichier Natif",
            IMAGE: "Images",
            PSD: "PSD",
            VECT_PDF: "PDF Vectoriel",
            PDF_BD: "PDF BD",
            VECT_ILLUSTRATOR: "Illustrator Vectoriel",
            DAM_DELIVERY: "Livraison DAM",
            NOT_VECT_ILLUSTRATOR: "Illustrator Non Vectoriel",
            NOT_VECT_PDF: "PDF Non Vectoriel",
            FONTS: "Assemblage des polices + FontDisclaimer",
            PREPA_3D: "Préparation 3D",
            DELIVERY_TEMPLATE: "Template de livraison",
            FTP_DEPOSIT: "Dépôt FTP",
            PACKZ: "PACKZ",
            AI: "AI",
            ARTPRO: "Artpro",
            ARTPRO_PLUS: "Artpro +",
            PDF_HD: "PDF HD",
            PSD_PHOTOGRAVURE: "PSD Photogravure",
        },
        WARN_DELIVERY: {
            TITLE: "Controle avant livraison !",
            RELECTURE: "Vous êtes en relecture : ",
            DESC: "Contrôlez la présence de tous les fichiers nécessaires à la livraison avant Zip. Faire un assemblage via Illustrator si nécessaire à cette étape.",
        },
        UNABLE_TO_SUBMIT: "Impossible de soumettre la demande",
        UNABLE_TO_SUBMIT_TEXT: "S'il s'agit de la relecture EXE, vérifiez que vous avez bien créé la fiche texte, et que celle-ci a également bien été envoyée.",
        UPDATE_DOC: "Auto-contrôle",
        NONE: "Brief en cours",
        READY: "Prêt",
        "CHECK-AUTO-CONTROL": "Auto-contrôle",
        "CHECK-QUALITY": "Contrôle qualité",
        "CHECK-REPRO": "Contrôle REPRO",
        "CHECK-PROD": "Contrôle graphique",
        "CHECK-CPP": "Contrôle CPP",
        "GENERATING": "Génération en cours",
        "PROD-INTERN-REJECTED": "Auto contrôle rejeté",
        "CPP-INTERN-REJECTED": "Contrôle CPP rejeté",
        WIP: "En production",
        'CONTROL-VALIDATED': "Contrôle validé",
        'CONTROL-FAILED': "Contrôle refusé",
        CONTROL_APPROVED: "Contrôle validé",
        CONTROL_REJECTED: "Contrôle non validé",
        CONTROL_PENDING: "En attente de proofscope",
        "WAITING-AUTO-CONTROL": "Document en attente de modifications (auto-contrôle)",
        "WAITING-CHECK-CPP": "Document en attente de modifications (Ctrl CPP)",
        "INTERN-FINISHED": "Contrôle CPP validé",
        "INTERN-FINISHED-OPERATOR": "Contrôle Opé. validé",
        "RE-DELIVER": "Relivraison",
        PR_CLIENT_INPROGRESS: "Envoi client",
        "PR-CLIENT-INPROGRESS": "Envoyé au client",
        "TO-DELIVER": "A livrer",
        "CONTROL-DELIVERY-VALIDATED": "Livraison validée",
        "CONTROL-DELIVERY-FAILED": "Livraison refusée",
        "DELIVERED": "Livraison finalisée",
        "SWITCH-TO-REPRO": "Passer en REPRO",
        BTN: {
            NEXT: {
                NONE: "Initialisée",
                READY: "Prêt",
                "CHECK-AUTO-CONTROL": "Auto-contrôle",
                "CHECK-QUALITY": "Ctrl qualité",
                "CHECK-REPRO": "Ctrl repro",
                "CHECK-PROD": "Ctrl graphique",
                "CHECK-CPP": "Ctrl CPP",
                RETURN_TO: "Retour à",
                CONTROL_APPROVED: "Contrôle validé",
                CONTROL_REJECTED: "Contrôle non validé",
                CONTROL_PENDING: "En attente de proofscope",
                "WAITING-AUTO-CONTROL": "Retour en production",
                "WAITING-CHECK-CPP": "Retour en production",
                "INTERN-FINISHED": "Contrôle CPP validé",
                "INTERN-FINISHED-OPERATOR": "Contrôle Opé. validé",
                PR_CLIENT_INPROGRESS: "Envoi client",
                "PR-CLIENT-INPROGRESS": "Envoyer au client",
                "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
                "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
                'CONTROL-VALIDATED': "Contrôle validé",
                'CONTROL-FAILED': "Contrôle refusé",
                WIP: "En production",
                "TO-DELIVER": "A livrer",
                "CONTROL-DELIVERY-VALIDATED": "Valider la livraison",
                "CONTROL-DELIVERY-FAILED": "Refuser la livraison",
                "DELIVERED": "Finaliser la livraison",
                "RE-DELIVER": "Relivraison",
                "SWITCH-TO-REPRO": "Passer en REPRO",
            },
            PREVIOUS: {
                NONE: "Initialiser",
                READY: "Réinitialiser",
                "CHECK-AUTO-CONTROL": "Auto-contrôle",
                "CHECK-QUALITY": "Ctrl qualité",
                "CHECK-REPRO": "Ctrl repro",
                "CHECK-PROD": "Ctrl graphique",
                "CHECK-CPP": "Ctrl CPP",
                RETURN_TO: "Retour à",
                CONTROL_APPROVED: "Contrôle validé",
                CONTROL_REJECTED: "Contrôle non validé",
                CONTROL_PENDING: "En attente de proofscope",
                "WAITING-AUTO-CONTROL": "Retour en production",
                "WAITING-CHECK-CPP": "Retour en production",
                "INTERN-FINISHED": "Contrôle CPP validé",
                "INTERN-FINISHED-OPERATOR": "Contrôle Opé. validé",
                PR_CLIENT_INPROGRESS: "Envoi client",
                "PR-CLIENT-INPROGRESS": "Envoyé au client",
                "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
                "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
                'CONTROL-VALIDATED': "Contrôle validé",
                'CONTROL-FAILED': "Contrôle refusé",
                WIP: "Retour en production",
                "TO-DELIVER": "Réinitialiser la livraison",
                "RE-DELIVER": "Relivraison",
                "CONTROL-DELIVERY-VALIDATED": "Retour en livraison validée",
                "CONTROL-DELIVERY-FAILED": "Retour en livraison refusée",
                "DELIVERED": "Finaliser la livraison",
                "SWITCH-TO-REPRO": "Passer en REPRO",
            }
        },
        DASHBOARD: {
            NULL: "Pas de relecture interne",
            NONE: "Brief en cours",
            UPDATE_DOC: "Doc modifié",
            READY: "Prêt",
            "CHECK-AUTO-CONTROL": "Auto-contrôle",
            "APPROVED": "Approuvé",
            "REJECTED": "Rejeté",
            "CHECK-QUALITY": "Ctrl qualité",
            "CHECK-REPRO": "Ctrl repro",
            "CHECK-PROD": "Ctrl graphique",
            "CHECK-CPP": "Ctrl CPP",
            WIP: "En production",
            CONTROL_APPROVED: "Ctrl validé",
            CONTROL_REJECTED: "Ctrl non validé",
            CONTROL_PENDING: "ATT proofscope",
            "WAITING-AUTO-CONTROL": "Modifs auto-ctrl",
            "WAITING-CHECK-CPP": "Modifs Ctrl CPP",
            "INTERN-FINISHED": "Contrôle CPP validé",
            "INTERN-FINISHED-OPERATOR": "Contrôle Opé. validé",
            "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
            "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
            PR_CLIENT_INPROGRESS: "Envoi client",
            "PR-CLIENT-INPROGRESS": "Envoyé au client",
            "GENERATING": "Génération en cours",
            RELECTURE_IN_PROGRESS: "Relecture client en cours",
            "TO-DELIVER": "A livrer",
            "CONTROL-DELIVERY-VALIDATED": "Livraison validée",
            "CONTROL-DELIVERY-FAILED": "Livraison refusée",
            "DELIVERED": "Livraison finalisée",
            "RE-DELIVER": "Relivré",
            "SWITCH-TO-REPRO": "Passer en REPRO",
        },
        APPROVE: {
            NONE: "Brief en cours",
            READY: "Document prêt pour la production.",
            CONTROL_TO_CPP: "À valider par le CPP",
            CONTROL_TO_QUALITY: "À valider par le ctrl qualité",
            CPP: "Ctrl validé",
            "INTERN-FINISHED": "Voulez-vous valider le contrôle CPP ?",
            "INTERN-FINISHED-OPERATOR": "Voulez-vous valider le contrôle Opé. ?",
            "PR-CLIENT-INPROGRESS": "Envoyer la relecture au client",
            "CHECK-AUTO-CONTROL": "Document modifié, à valider en auto-contrôle",
            "CHECK-QUALITY": "Document ok, à valider par le Ctrl qualité",
            "CHECK-REPRO": "Document ok, à valider par le Ctrl repro",
            "CHECK-PROD": "Document ok, à valider par le Ctrl graphique",
            "CHECK-CPP": "Document ok, à valider par le CPP",
            "PROD-INTERN-REJECTED": "Rejeter l'auto-contrôle",
            "CPP-INTERN-REJECTED": "Rejeter le contrôle du CPP",
            WIP: "Passer le job en production",
            "CONTROL-FAILED": "Document contrôlé, déclaré non valide.",
            "CONTROL-VALIDATED": "Document contrôlé, déclaré valide.",
            "TO-DELIVER": "Initialiser la livraison",
            "CONTROL-DELIVERY-VALIDATED": "Retour en livraison valide",
            "CONTROL-DELIVERY-FAILED": "La livraison est non-conforme",
            "DELIVERED": "Contrôlez pour voir si tout s'est bien passé ! Si oui, poursuivez, sinon réinitialisez la livraison.",
            "RE-DELIVER": "Voulez-vous relivrer le job ?",
            "SWITCH-TO-REPRO": "Voulez-vous passer en REPRO ?",
        },
        REJECT: {
            QUALITY: "Ctrl qualité non validé",
            GRAPHIC: "Ctrl graphique non validé",
            OPERATOR: "Auto-contrôle non validé",
            REJECT: "Ctrl non validé",
            DOCUMENT: "Document à modifier",
            "CHECK-CPP": "Document à valider par le CPP"
        }
    },
    LINK_MASTER_REFERENCE: "Liaison entre projet parent et enfant",
    MAIL: "Email",
    TABLE_PROJECT: "Mes projets",
    TABLE_REQUESTS: "Mes demandes",
    MENU: {
        USERS: "Utilisateurs",
        GROUPS: "Groupes",
        MY_ACCOUNT: "Mon compte",
        PROJECTS: "Projets",
        LOG_OUT: "Déconnexion",
        COLORS: "Nuanciers",
        COLORBOOKS: "Nuancier (couleurs)",
        FINISHBOOKS: "Nuancier (finitions)",
        OPEN_FOLDER: "Ouvrir le dossier",
        BRIEF: "Fiche de brief",
        CREATE: "Créer une fiche textes",
        CREATE_CHILD: "Créer un projet enfant",
        CREATE_MASTER: "Créer un projet parent",
        CREATE_PRINTER: "Créer un imprimeur",
        CREATE_PROJECT: "Créer un projet",
        CREATE_REQUEST: "Créer une demande",
        CREATE_USER: "Créer un utilisateur",
        EDIT_PROJECT: "Éditer un projet enfant",
        FORM: "Fiches textes",
        KIOSK: "Cloudflow",
        MENU: "Menu",
        EASYDIADEM: "EasyDiadem",
        PRINTER: "Imprimeurs",
        HOME: "Accueil",
        PROJECT: "Projet",
        SPLITVIEW: "Relecture client",
        TABLE_PROJECT: "Mes projets",
        TABLE_PRINTER: "Imprimeurs",
        TABLE_USER: "Utilisateurs",
        UPDATE: "Éditer une fiche textes",
        ACCOUNT: "Mon compte",
        STATS: "KPI",
        ED: {
            ED_REQUESTS: "Mes demandes",
            NEW_REQUEST: "Nouvelle demande"
        }
    },
    PREVIEW_CARTOUCHE: {
        NUM_JOB: "Numéro de job",
        RANGE: "Marque",
        FOLDER: "Dossier",
        DATE: "Date",
        REPRO_OPERATOR: "Infographiste REPRO",
        EXE_OPERATOR: "Infographiste EXE",
        IMAGE: "Imports images",
        GENCOD: "GENCOD",
        PRINTER: "Imprimeur",
        PRINT: "Impression",
        PLAN: "Plan",
        SUPPORT: "Support",
        PROFIL: "PROFIL"
    },
    PROJECT: {
        ALL_CUSTOMER: "Tous les clients",
        MEMBERS: {
            FILTER_PLACEHOLDER: "Rechercher un utilisateur...",
            TABLE: {
                NAME: "NOM",
                PROOFSCOPE: "PROOFSCOPE",
                TEXTS: "TEXTES",
                APPROVERS: "APPROBATION",
                VERSIONS: "VERSION/AVIS",
                EMAIL: "E-MAIL",
                ORDER: "ORDRE",
                READ: "Lecture",
                WRITE: "Ecriture",
                INFO: "Info",
                PREEMPTIVE_RIGHT: "PREEMPT.",
                PREEMPTIVE_RIGHT_LONG: "Droit de préemption",
                
                DOCUMENT: "Document",
                ERROR: {
                    APPRODOC: "• Aucun membre n'est désigné approbateur du document",
                    ATLEASTONEINFO: "• Au moins un approbateur pour information requis",
                    MAXIMUMONE: "• Il ne peut y avoir qu'un unique approbateur du document"
                }
            }
        },
        CART: {
            CART: "Cartouche",
            FILETMINNEG: "Filet minimum négatif",
            FILETMINPOS: "Filet minimum positif",
            HAUTCHIFFREPOIDS: "Hauteur des chiffres poids/volumes.",
            HAUTEMETRO: "Hauteur E Métrologique",
            HAUTXMIN: "Hauteur de x minuscule",
            MINTXTNEG: "Texte minimum négatif",
            MINTXTPOS: "Texte minimum positif",
            POLICEUSED: "Police utilisé",
            PREFILL: "Pré-remplir cartouche",
            SHOW_PREVIEW: "Regénérer la prévisualisation",
            SHOW_PREVIEW_EXE: "Cartouche EXE",
            SHOW_PREVIEW_REPRO: "Cartouche REPRO",
            GENERATE_PREVIEW: "Générer le cartouche",
            GENERATING_FILE: "Génération du fichier",
            ERROR_GENERATING_FILE: "Erreur lors de la génération du fichier"
        },
        CHILD: "Projet enfant",
        COMM: {
            CHROM: "Commentaire Chromie",
            COMM: "Commentaires",
            CPP: "Commentaire CPP",
            GAMME: "Commentaire Gamme",
            HD: "Commentaire HD",
            IMP: "Commentaire Imprimeur",
            PREPA: "Commentaire Preparation"
        },
        CREA: {
            COMM: "Commentaire",
            CREA: "Créa",
            INFO: "Informations"
        },
        CREATE: "Créer projet",
        CREATED_AT: "créé le",
        EXE: {
            BOOKPACK: "Bookpack",
            CHARTE: "Charte Client",
            COMM_CPP: "Commentaire CPP",
            BRIEF: "Brief Exe",
            DEF: "Définition",
            EXE: "Exe",
            IMAGE: "Image",
            LANGUES: "Ordre des langues",
            LOGO: "Logo à executer",
            NB_LANGUES: "Nombre de langues",
            OPERATOR: "Infographiste Exe",
            SOFT: "Logiciels",
            TEXTS: {
                ARCHIVE: "Archive",
                BRIEF: "Brief Client/Team",
                CLIENT: "Fiche Texte Client",
                CREA: "Créa",
                TEXTS: "Textes"
            }
        },
        FILTERS: {
            OLD_PROJECTS: "Anciens projets",
            PROJECTS_ARCHIVED: "Projets archivés",
            IN_APPROVAL_STATUS: "En attente de validation",
            DISPLAY_CLOSED: "Afficher les requêtes déjà traitées",
            MY_PROJECTS: "Mes projets",
            PROJECTS: "Projets en cours",
            DATE: "Date",
            DATES: "Dates",
            DATE_RANGE: {
                START_DATE: "Début",
                END_DATE: "Fin"
            },
            DATE_RANGE_ERRORS: {
                START_DATE: "Début",
                END_DATE: "Fin"
            },
            RESET: "Réinitialiser",
            SEARCH: "Lancer la recherche",
            REQUEST_TYPE_LABEL: "Type de requête",
            REQUEST_TYPE: {
                NONE: "Aucun",
                VISUALS_ISOLATION: "Isolation de visuel",
                PDF_REPOSITORY: "Dépôts de PDF"
            },
            REDELIVERY_TYPE_LABEL: "Type de relivraison",
            REDELIVERY_TYPE: {
                EXE: "EXE",
                REPRO: "REPRO",
                EZD_PDF_REPOSITORY: "EZD Dépôt de PDF"
            }
        },
        HD: {
            ARCHIVE: "Reprise d’archive",
            COMMCUSTOMER: "Description HD",
            ELEMENTS: "Éléments pour la HD",
            HD: "HD",
            HDSHOOT: "Shutter",
            OPERATOR: "Infographiste HD",
            PLANNING: {
                EPSON: "Epson 1 Client / TC",
                LIVRAISON: "Livraison",
                PLANNING: "Planning",
                R0: "R0 Master",
                R1: "R1 Master"
            },
            SHOOTCUSTOMER: "Shoot Client",
            SHOOTER: "Shoot HD"
        },
        INFO: {
            AGENCE: "Agence",
            APPROVER1: "Approbateur 1",
            APPROVER2: "Approbateur 2",
            APPROVER3: "Approbateur 3",
            APPROVER4: "Approbateur 4",
            APPROVERS: "Approbateurs",
            MEMBERS: "Membres",
            ARCHIVE: "Archive",
            BASE: "Base de travail",
            CHILD: "Numéro Enfant",
            CODEARTICLE: "Code Article",
            CONTACT: "Contact",
            CONTRAINTE: "Contraintes Techniques (imprimeur)",
            CPP: "CPP",
            CREA: "Créa",
            CREA_NAME: "Nom + N°Créa",
            CREA_TYPE: "Type de créa",
            CUSTOMER: "Client",
            CUSTOMER_INVOICE: "Client facturation (optionnel)",
            CUSTOMER_CODE: "Code client",
            DATEVALID: "Date validation",
            DESIGNATION: "Désignation",
            DESIGNATIONGAMME: "Désignation",
            DESIGNATION_PRODUIT: "Désignation produit",
            FAMILY: "Famille",
            FILETMINN: "Filet mini Négatif",
            FILETMINP: "Filet mini Positif",
            IDENTIFIANT: "Identifiant",
            IDENTIFIER: "Numéro du Job Enfant",
            IDMAITRE: "Numéro Job Maître",
            INFO: "Informations Générales",
            LANGUAGES: "Langues",
            MASTER: "Numéro Master",
            NUMCREA: "N° Créa validée",
            NUMDIADEM: "Numéro Diadem",
            NUMTEAM: "Numéro Team Créatif",
            OPERATOR: "Infographiste référent",
            PLAN: "Plan",
            PRINT: "Impression",
            PRINTER: "Imprimeur",
            PROCESS: "Process",
            RANGE: "Marque",
            REFNUMBER: "Nombre de référence",
            RELECTURE_JOB: "Numéro de relecture",
            RELECTURE_JOB_EXE: "N° de relecture EXE",
            RELECTURE_JOB_REPRO: "N° de version REPRO",
            SERVICE: "Services",
            STATUS: "Statut",
            SUPPORT: "Support",
            TEXTMININ: "Textes mini Négatif",
            TEXTMINIP: "Textes mini Positif",
            TYPEJOB: "Type",
            ZONEDEBORDDEV: "Zone débord Developpement",
            ZONEDEBORDLAIZE: "Zone débord Laize",
            ZONESECUCOUP: "Zone sécurité coupe",
            ZONESECURAIN: "Zone sécurité raineur",
            SUPPORT_COMM: "Spécificités imprimeur",
            RIVERFLOW: "Riverflow",
            RIVERFLOW_JOBTYPES: "Type de job a créer",
            RIVERFLOW_DATE: "Date du Job",
            CREATE_IN_RIVERFLOW: "Créer le Job dans Riverflow"
        },
        LAST_REVISION: "Dernier Ctrl client (exe) le",
        LAST_VERSION: "Dernier Ctrl client (repro) le",
        MASTER: "Projet parent",
        OTHER: "Autre",
        PREFILL: "Pré-remplir les champs",
        EASYDIADEM: "Easydiadem",
        REPRO: {
            CHROMIE: {
                CHROMIE: "Chromie",
                CIBLE: "Cible couleur fournie",
                ENCR: "Encrage maximum",
                PTMAX: "Point maxi",
                PTMIN: "Point mini",
                REPROPROFIL: "Profil",
                TRAM: "Trame"
            },
            FILE1: "Fichiers à fournir",
            FILE2: "PSD",
            OPERATOR: "Infographiste REPRO",
            REPRO: "REPRO",
            RETRAIT: {
                BLANC: "Retrait blanc",
                COULEUR: "Retrait couleur",
                VERNIS: "Retrait Vernis"
            },
            TRAP: "Trapping",
            TRAP_RETRAIT: "Trapping et Retrait"
        },
        SAVE: "Sauvegarder projet",
        SEARCH: "Rechercher un projet...",
        TYPE_JOB_REQUIRED: "Type de job requis"
    },
    PROJECTS: "Projets",
    PROJECTTABLE: {
        PAGE_TITLE: "Table des projets"
    },
    FACE: "De Face",
    "THREE-QUARTER-LEFT": " De 3/4 à gauche",
    "THREE-QUARTER-RIGHT": " De 3/4 à droite",
    "FACE-INCO": "Face légale INCO",
    RESTART: "Redémarrer",
    REQUIRED: "Champ requis",
    PR_REQUIRED: "EXE : Penser à créer une fiche texte (pour éviter le plantage de flux)",
    RESET_DIALOG: {
        TEXT: "Les textes et les champs personnalisés seront supprimés dans toutes les langues",
        TITLE: "Voulez-vous réinitialiser la fiche textes ?"
    },
    SAVE: "Sauvegarder",
    SELECT_JOB_CHILD_REFERENCE: "Sélectionner un projet enfant",
    SELECT_JOB_MASTER_REFERENCE: "Sélectionner un projet parent",
    SELECT_FILTER_FIELDS: "Cliquez ici pour sélectionner les champs de la fiche textes",
    SELECT_REFERENCE: "Sélectionner une référence",
    SHOOTING: "Quelle(s) prise(s) de vue pour votre Packshot (il sera livré aux formats jpeg, png - Basse déf et Haute déf) : ",
    SELECT_SHOOTING: "Merci de sélectionner les prises de vue",
    SOMETHING_WENT_WRONG: "Une erreur est survenue",
    SPLITVIEW: {
        PAGE_TITLE: "VUE COMPAREÉ"
    },
    SUBMIT: "Envoyer",
    SUCCESS: {
        CREATE: "Vos textes ont été créés",
        FORM: {
            CREATE: "Votre fiche textes a été créée",
            SAVE: "Votre fiche textes a été enregistré",
            SUBMIT: "Fiche textes envoyée !"
        },
        SAVE: "Vos textes ont été enregistrés",
        SUBMIT: "Textes envoyés !"
    },
    SYSTEM: "Système",
    TABLE: "Tableau des projets",
    COMPARE: "Comparer",
    TEL: "Téléphone",
    TEXT: {
        COPIED: "Texte copié !",
        PASTED: "Texte collé depuis le presse papier"
    },
    TEXTOK: {
        SUBMIT: "Textes envoyés !",
        TITLE: "Textes ok"
    },
    TEXT_APPRO_IN_PROGRESS: "Traitement approbateurs",
    TEXT_IN_PROGRESS: "En cours de traitement",
    TEXT_SEND: "Textes envoyés",
    TITLE: "TITRE",
    ARE_YOU_SURE: "Êtes-vous sûr ?",
    ARE_YOU_SURE_ARCHIVE: "Le projet sera archivé",
    ARE_YOU_SURE_UNARCHIVE: "Le projet sera désarchivé",
    ARE_YOU_SURE_DELETE_GROUP_TEXT: "Le groupe sera supprimé. (Les groupes supprimés seront retirés des projets en cours)",
    ARE_YOU_SURE_DELETE_LANGUAGES: "Les textes de cette langue seront supprimés",
    DELETE: "Supprimer",
    TOOLBAR: {
        IN_PRODUCTION: 'Envoyer en production',
        CREATE: "Créer fiche textes",
        FIELD: "Ajouter un champ personnalisé",
        CUSTOM_COLUMN: 'Ajouter une colonne personnalisé',
        LANGUAGE: "Ajouter une langue",
        FORM: "Réinitialiser la fiche textes",
        PREFILL: "Pré-remplir les champs",
        SUBMIT: "Envoyer la fiche textes",
        SUBMIT_MODIF: "Envoyer les modifications"
    },
    TRAME: "Linéature de trame",
    TYPE_TRAME: "Type de Trame",
    TXT_SUBMIT: "Textes envoyés",
    TYPE: "File Epson",
    UNSAVED_CHANGES: "Des modifications n'ont pas été sauvegardées. Quitter quand même ?",
    USERS: "Utilisateurs",
    GROUPS: "Groupes",
    USER: {
        ARE_YOU_SURE_DELETE_USER_TEXT: "L'utilisateur sera supprimé",
        BOTH_USED: "Nom d'utilisateur et e-mail déjà utilisés",
        CREATE: "Créer l'utilisateur",
        CREATE_OTHER: "Souhaitez-vous créer un autre utilisateur ?",
        CREATE_USER: "Créer un utilisateur",
        ADD_USER: "Ajouter un utilisateur",
        CUSTOMER: "Client",
        DELETE: "Supprimer",
        DELETE_SUCCESS: "Utilisateur supprimé",
        EDIT_ACCOUNT_SUCCESS: "Compte mis à jour",
        EDIT_SUCCESS: "Utilisateur mis à jour",
        ADD_SUCCESS: "Utilisateur créé",
        EDIT_FAILED: "Impossible de mettre à jour l'utilisateur",
        EDIT_USER: "Éditer l'utilisateur",
        EMAIL: "E-mail",
        FIRSTNAME: "Prénom",
        MANAGE_USERS: "Gérer les utilisateurs",
        GROUP: "Groupe",
        LASTNAME: "Nom",
        MAIL_USED: "E-mail déjà utilisé",
        MY_ACCOUNT: "Mon compte",
        PASSWORD: {
            CURRENT: "Mot de passe actuel",
            NEW: "Nouveau mot de passe",
            NEW_CONFIRM: "Confirmer le nouveau mot de passe",
            NOT_SAME: "Les deux mots de passe sont différents",
            RESET: "Modifier mon mot de passe",
            UPDATE_SUCCESS: "Mot de passe mis à jour",
            WRONG_CURRENT: "Mot de passe actuel incorrect"
        },
        UPDATE: "Mettre à jour",
        USER: "Utilisateur",
        USERNAME: "Login",
        USERNAME_USED: "Nom d'utilisateur déjà utilisé",
        IS_DIADEM: "L'utilisateur fait partie de Diadem",
        IS_DIADEM_SHORT: "Utilisateurs Diadem",
    },
    USERNAME: "Nom d'utilisateur",
    SELECT_DOC: "Merci de sélectionner un document",
    GROUP: {
        DELETE_SUCCESS: "Groupe supprimé",
        CREATE: "Créer le groupe",
        CREATE_GROUP: "Ajouter un groupe",
        CUSTOMER: "Client",
        CUSTOMERS: "Clients",
        CPP: "CPP",
        MANAGE_GROUPS: "Gérer les groupes",
        BRANDS: "Marques",
        DELETE: "Supprimer",
        EDIT_GROUP: "Éditer le groupe",
        EDIT_SUCCESS: "Groupe mis à jour",
        GROUP: "Groupe",
        UPDATE: "Mettre à jour",
        NAME: "Nom",
        USERS: "Utilisateurs",
        APPROBATION_BEHAVIOR: "Comportement d'approbation",
        ONE_FOR_ALL: "Un vaut pour tous",
        INDIVIDUAL: "Individuel",
        ONE_FOR_ALL_DESCR: "N'importe quel membre du groupe peut choisir de valider/rejeter l'approbation, et suite à son choix le statut de tout le groupe est affecté en conséquence.",
        INDIVIDUAL_DESCR: "Chaque membre du groupe doit valider/rejeter l'approbation avant que le statut du groupe soit calculé en conséquence."
    },
    INVALID_FILE: "Le fichier est invalide",
    INVALID_FILE_SIZE: "Le fichier est trop volumineux",
    INVALID_FILE_TYPE: "Le type du fichier est incorrecte",
    VALID: "Valider",
    VALIDATORS: {
        "FORMDIALOG-CPP": "Veuillez sélectionner un CPP valide",
        "FORMDIALOG-PRINTERS": "Veuillez sélectionner un imprimeur valide",
        "FORMDIALOG-CUSTOMER": "Veuillez sélectionner un client valide",
        "FORMDIALOG-IDENTIFIANT": "Veuillez sélectionner un identifiant valide",
        "FORMDIALOG-FAMILY": "Veuillez sélectionner une famille valide",
        "FORMDIALOG-FORM": "Veuillez saisir un nom de champ",
        "FORMDIALOG-LANGUAGE": "Veuillez sélectionner au moins une langue",
        "FORMDIALOG-OPERATOR": "Veuillez sélectionner un infographiste valide",
        "FORMDIALOG-PROJECT": "Veuillez sélectionner un projet valide",
        "FORMDIALOG-RANGE": "Veuillez sélectionner une marque valide",
        "FORMDIALOG-CONTACT": "Veuillez sélectionner un contact valide",
        "FORMDIALOG-CUSTOMER_INVOICE": "Veuillez sélectionner un client de facturation valide"
    },
    VALID_EMAIL: "Email non valide",
    INVALID_USERNAME: "Username invalide",
    INVALID_PASSWORD: "Mot de passe invalide",
    VALID_TEL: "Téléphone non valide",
    VIEW: {
        BUTTON: "Voir",
        PROJECT_REF: "Référence",
        ID_MAITRE: 'ID Maître',
        COMPARE: "Comparer",
        DOCUMENT_APPROVER_REJECT: "L'approbateur a rejeté le document.",
        DOCUMENT_YOU_ACCEPT: "Vous avez accepté le document.",
        DOCUMENT_YOU_REJECT: "Vous avez rejeté le document.",
        DOCUMENT_APPROVER_ACCEPT: "L'approbateur a apprové le document.",
        INTERN_CONTROL_REJECT: "Le contrôle interne a été rejeté.",
        INTERN_CONTROL_APPROVED: "Le contrôle interne a été approuvé.",
        DOCUMENT_IN_PRODUCTION: "Le document est en cours de production.",
        PENDING_DELIVERY: "En attente de livraison",
    },
    WARNING: "Attention",
    REPRO_IN_PROGRESS: "REPRO en cours",
    EXE_IN_PROGRESS: "Exe en cours",
    YES: "Oui",
    NO: "Non",
    ENTER_VALUE: "Entrez une valeur",
    EDIT: "Editer",
    MUST_BE_IN_LIST: "L'element doit etre issue de la liste",
    SYNCBDD: "Synchroniser la base client Logipub",
    TXT_PR: "Fiche Textes",
    VALIDATE: "Valider",
    NEXT: "Suivant",
    PREVIOUS: "Précédent",
    REMOVE_LANGUE: "Supprimer la langue",
    SEARCH: "Rechercher",
    SELECT_LANGUAGE: "Sélectionner une ou plusieurs langues",
    SERVICE: {
        PROJECT: 'Projets',
        VALIDATORS: {
            "PROJECT": "Veuillez sélectionner un projet valide",
            "PRINTERS": "Veuillez sélectionner un imprimeur valide"
        },
        PRINTERS: 'Imprimeurs'
    },
    NOTIFICATIONS: {
        LIST: 'Notifications',
        CARTOUCHE_GENERATED: "Le cartouche {{ type }} du projet {{ projectIdentifier }} a été généré !",
        PROOFSCOPE_GENERATED: "{{ identifier }} - La relecture interne {{ flow }} à été mis-à-jour !",
        FILE_VALIDED: "{{ identifier }} - La relecture interne {{ flow }} est terminée !",
        SENT_TO_CLIENT: "{{ identifier }} - La relecture interne a été publiée au client",
        CHECK_CPP: "{{ identifier }} - Un job dont vous êtes CPP est prêt à être contrôlé.",
        BRIEF_FILES_ARE_READY: "{{ identifier }} - Les fichiers du brief client ont été copiés",
        FILES_ARE_READY: "{{ identifier }} - Les fichiers ont été copiés",
        START_DELIVERY: "{{ identifier }} - La livraison est prête à être contrôlée",
        CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - Contrôle de livraison terminé, livraison validée",
        CONTROL_DELIVERY_FAILED: "{{ identifier }} - Contrôle de livraison terminé, livraison refusée",
        FINAL_DELIVERY: "{{ identifier }} - La livraison a été finalisée",
        ERROR: {
            UPDATE_DOC: "{{ identifier }} - Le flux de production a planté en {{ flow }}",
            VALID_FILE: "{{ identifier }} - Le flux de validation du fichier a planté en {{ flow }}",
            BRIEF_FILES_ARE_READY: "{{ identifier }} - Les fichiers n'ont pas pu être copiés",
            FILES_ARE_READY: "{{ identifier }} - Les fichiers n'ont pas pu être copiés",
            START_DELIVERY: "{{ identifier }} - La livraison a planté en {{ flow }}",
            CONTROL_DELIVERY_FAILED: "{{ identifier }} - Le refus du contrôle de livraison a planté en {{ flow }}",
            CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - La validation du contrôle de livraison a planté en {{ flow }}",
            FINAL_DELIVERY: "{{ identifier }} - La finalisation de la livraison a planté en {{ flow }}",
            POST_VALIDATION: "{{ identifier }} - La post-validation a planté en {{ flow }}",
        }
    },
    JOB_NO_PROOFSCOPE: "Ce job n'a pas de fiche textes associée",
    RESET_PROD: {
        BTN: "Réinitialiser la production",
        TITLE: "Réinitialisation de la production",
        TEXT: "Ceci va réinitialiser la relecture à l'etat 'Prêt'."
    },
    UPLOADING: 'Chargement',
    UPLOADING_IN_PROGRESS: "Les fichiers sont en train d'être chargés.",
    DOWNLOADING: 'Téléchargement',
    DOWNLOADING_IN_PROGRESS: "Les fichiers sont en train d'être téléchargés.",
    ACTUAL_PROJECTS: "Projets en cours",
    ADD_CHILD_PROJECT: "Ajouter projet enfant",
    THUMB: "Vignette",
    JOB_NAME: "Nom du job",
    EXTERN_STATE: "Statut général",
    INTERN_STATE: "Statut interne",
    CREATION_DATE: "Créé par",
    ACTIONS: "Actions",
    ED_JOB: "Projet EasyDiadem",
    DASHBOARDHISTORY: {
        VALIDATIONS_HISTORY: "Historique des validations",
        LOOP: {
            INTERN: "Boucle interne",
            EXTERN: "Boucle externe",
        },
        ORDER: "Ordre",
        PLEASE_SELECT_A_VERSION: "Veuillez sélectionner une version",
        USER: "Utilisateur",
        DATE_HOUR: "Date / heure",
        STATE: "Etat",
        NO_HISTORY: "Aucun historique disponible",
        STEPS: "Etapes",
        MEMBERS: "Membres",
        CATEGORY: "Catégorie",
        CONTROL: "Contrôle",
    },
    VERSION_SHORTNAME: "ver.",
    NO_VERSION: "Pas de version",
    SPLITVIEWS: "Relectures",
    INTERN_TITLE: "Interne",
    INTERN_TITLE_SHORT: "Int.",
    EXTERN_TITLE: "Externe",
    EXTERN_TITLE_SHORT: "Ext.",
    COMPARE_SHORT: "Comp.",
    RELECTURES: "Relectures",
    SELECT_DOCUMENT_TO_COMPARE: "Sélectionner les versions à comparer",
    DOCUMENT_A: "Document A",
    DOCUMENT_B: "Document B",
    OR: "ou",
    DETAILS: "Détails",
    SEE_PROJECT_HISTORY: "Voir l'historique du projet",
    LAST_MONTH: "Le mois dernier",
    LAST_SIX_MONTHS: "Les six derniers mois",
    LAST_THREE_MONTHS: "Les trois derniers mois",
    LAST_YEAR: "L'an dernier",
    FILTERS: {
        NAME_OR_ID: "Nom ou identifiant",
        START_DATE: "Date début",
        END_DATE: "Date fin",
        REINIT: "Réinitialiser",
        SEARCH: "Recherche",
        PERIOD: "Sélectionner une période",

    },
    RELECTURE: "Relecture",
    YOU_DONT_HAVE_THE_PRIVILEGE: "Vous n'avez pas le privilège",
    EZD_TABLE: {
        TITLE: {
            DATE: "Date",
            CLIENT: "Client",
            REF_REQUEST: "Réf. demande",
            REF_PROJECT: "Réf. projet",
            SOURCE_PROJECT: "Projet d'origine",
            PLANNED_SUB: "Soumission prévue",
            NAME: "Nom de la demande",
            TYPE: "Type de demande",
            INFO: "Info",
            ACTIONS: "Actions"
        }
    },
    RESET_PASSWORD: "Réinitialiser le mot de passe",
    ARE_YOU_SURE_RESET_PASSWORD_TEXT: "Le mot de passe de l'utilisateur {{ username }} sera réinitialisé.",
    ARE_YOU_SURE_RESET_PASSWORD_TEXT_2: "Il recevra son nouveau mot de passe par email.",
    ATTRIBUTES_NAME: {
        TITLE: "Attributs de l'utilisateur",
        CPP: "CPP",
        EXE: "Exe",
        REPRO: "Repro",
        FAB: "Fab",
        OPERATOR: "Opérateur",
        CLIENT: "Client",
    },
    FORGOT_PASSWORD: "Mot de passe oublié ?",
    USERNAME_OR_EMAIL: "Nom d'utilisateur ou email",
    REQUIRED_USERNAME_OR_EMAIL: "Nom d'utilisateur ou email requis",
    ASK_FOR_REINIT: "Demander la réinitialisation",
    ASK_FOR_REINIT_TEXT: "Votre demande a bien été prise en compte. Si l'adresse email associée au compte est valide, vous recevrez un email avec un lien pour réinitialiser votre mot de passe.",
    SET_NEW_PASSWORD: "Définir un nouveau mot de passe",
    BACK_TO_LOGIN_PAGE: "Retour à la page de connexion",
    RESET_PASSWORD_DIALOG: {
        ERROR: {
            TITLE: "Une erreur est survenue",
            TEXT: "Merci de vérifier les informations fournies, ou réessayez plus tard."
        },
        REQUEST: {
            SUCCESS: {
                TITLE: "Demande de réinitialisation envoyée",
                TEXT: "Si le nom d'utilisateur ou l'email fourni correspond à un compte existant, vous recevrez un email avec un lien pour réinitialiser votre mot de passe."
            }
        },
        SET: {
            SUCCESS: {
                TITLE: "Mot de passe mis à jour",
                TEXT: "Vous pouvez désormais vous connecter avec votre nom d'utilisateur et votre nouveau mot de passe."
            }
        }
    },
    NEW_USER: "Nouvel utilisateur",
    INVALID_CLIENT: "Client non valide, accedez a cette page depuis la tables des groupes",
    INVALID_MARQUE: "Marque non valide, accedez a cette page depuis la tables des groupes",
    FINISH_SELECTION: "Sélectionner une finition",
    GROUPS_USERS: "Utilisateurs du groupe",
    FIND: "Rechercher",
    SELECT_PAGE_OF_GROUPS: "Sélectionner une page de groupes",
    EX_PRINTER: "Ex. Imprimeur",
    SELECT_PAGE_OF_USERS: "Sélectionner une page d'utilisateurs",
    REQUEST: "Requête",
    NEW_REQUEST_DISCLAIMER: "J'ai compris que cette prestation sera intégralement gérée via la plateforme <strong>Easy Diadem</strong>, sans relation directe avec vos contacts habituels chez Diadem. Pour toute demande de prestation jugée trop complexe, ou <strong>en cas de prise de contact autrement que par la plateforme Easy Diadem, Diadem se réserve le droit de réaliser un devis additionnel en fonction de la complexité du travail demandé.</strong>",
    LANG_SELECTION: "Sélection des langues",
    NEW_LANG: "Nouvelle langue",
    LANG_TRANSLATED: "Langue/Language",
    FILE_REDELIVERY_DESC: `Relivraison des fichiers {{ type }} pour le projet {{ projectIdentifier }}`,
    LB: {
        ALL: 'Tout',
        WAITING: 'En attente de traitement',
        IN_PROGRESS: 'Demande en cours',
        DELIVERED: "Livrée",
        CANCELLED: "Annulée",
    },
    CPP: "CPP",
    FAB: "Fab",
    OPERATOR: "Opérateur",
    CLIENT: "Client",
    USER_MANUAL: "Manuel utilisateur",
    LANGS: {
        'aa': 'Afar',
        'ab': 'Abkhaze',
        'ae': 'Avestique',
        'af': 'Afrikaans',
        'ak': 'Akan',
        'am': 'Amharique',
        'an': 'Aragonais',
        'ar': 'Arabe',
        'as': 'Assamais',
        'av': 'Avar',
        'ay': 'Aymara',
        'az': 'Azéri',
        'ba': 'Bachkir',
        'be': 'Biélorusse',
        'bg': 'Bulgare',
        'bh': 'Bihari',
        'bi': 'Bichelamar',
        'bm': 'Bambara',
        'bn': 'Bengali',
        'bo': 'Tibétain',
        'br': 'Breton',
        'bs': 'Bosnien',
        'ca': 'Catalan',
        'ce': 'Tchétchène',
        'ch': 'Chamorro',
        'co': 'Corse',
        'cr': 'Cri',
        'cs': 'Tchèque',
        'cu': 'Vieux-slave',
        'cv': 'Tchouvache',
        'cy': 'Gallois',
        'da': 'Danois',
        'de': 'Allemand',
        'dv': 'Maldivien',
        'dz': 'Dzongkha',
        'ee': 'Ewe',
        'el': 'Grec moderne',
        'en': 'Anglais',
        'eo': 'Espéranto',
        'es': 'Espagnol',
        'et': 'Estonien',
        'eu': 'Basque',
        'fa': 'Persan',
        'ff': 'Peul',
        'fi': 'Finnois',
        'fj': 'Fidjien',
        'fo': 'Féroïen',
        'fr': 'Français',
        'fy': 'Frison occidental',
        'ga': 'Irlandais',
        'gd': 'Écossais',
        'gl': 'Galicien',
        'gn': 'Guarani',
        'gu': 'Gujarati',
        'gv': 'Mannois',
        'ha': 'Haoussa',
        'he': 'Hébreu',
        'hi': 'Hindi',
        'ho': 'Hiri motu',
        'hr': 'Croate',
        'ht': 'Créole haïtien',
        'hu': 'Hongrois',
        'hy': 'Arménien',
        'hz': 'Héréro',
        'ia': 'Interlingua',
        'id': 'Indonésien',
        'ie': 'Occidental',
        'ig': 'Igbo',
        'ii': 'Yi',
        'ik': 'Inupiak',
        'io': 'Ido',
        'is': 'Islandais',
        'it': 'Italien',
        'iu': 'Inuktitut',
        'ja': 'Japonais',
        'jv': 'Javanais',
        'ka': 'Géorgien',
        'kg': 'Kikongo',
        'ki': 'Kikuyu',
        'kj': 'Kuanyama',
        'kk': 'Kazakh',
        'kl': 'Groenlandais',
        'km': 'Khmer',
        'kn': 'Kannada',
        'ko': 'Coréen',
        'kr': 'Kanouri',
        'ks': 'Cachemiri',
        'ku': 'Kurde',
        'kv': 'Komi',
        'kw': 'Cornique',
        'ky': 'Kirghiz',
        'la': 'Latin',
        'lb': 'Luxembourgeois',
        'lg': 'Ganda',
        'li': 'Limbourgeois',
        'ln': 'Lingala',
        'lo': 'Lao',
        'lt': 'Lituanien',
        'lu': 'Luba',
        'lv': 'Letton',
        'mg': 'Malgache',
        'mh': 'Marshallais',
        'mi': 'Maori de Nouvelle-Zélande',
        'mk': 'Macédonien',
        'ml': 'Malayalam',
        'mn': 'Mongol',
        'mo': 'Moldave',
        'mr': 'Marathi',
        'ms': 'Malais',
        'mt': 'Maltais',
        'my': 'Birman',
        'na': 'Nauruan',
        'nb': 'Norvégien bokmål',
        'nd': 'Sindebele',
        'ne': 'Népalais',
        'ng': 'Ndonga',
        'nl': 'Néerlandais',
        'nn': 'Norvégien nynorsk',
        'no': 'Norvégien',
        'nr': 'Nrebele',
        'nv': 'Navajo',
        'ny': 'Chichewa',
        'oc': 'Occitan',
        'oj': 'Ojibwé',
        'om': 'Oromo',
        'or': 'Oriya',
        'os': 'Ossète',
        'pa': 'Pendjabi',
        'pi': 'Pali',
        'pl': 'Polonais',
        'ps': 'Pachto',
        'pt': 'Portugais',
        'qu': 'Quechua',
        'rm': 'Romanche',
        'rn': 'Kirundi',
        'ro': 'Roumain',
        'ru': 'Russe',
        'rw': 'Kinyarwanda',
        'sa': 'Sanskrit',
        'sc': 'Sarde',
        'sd': 'Sindhi',
        'se': 'Same du Nord',
        'sg': 'Sango',
        'sh': 'Serbo-croate',
        'si': 'Cingalais',
        'sk': 'Slovaque',
        'sl': 'Slovène',
        'sm': 'Samoan',
        'sn': 'Shona',
        'so': 'Somali',
        'sq': 'Albanais',
        'sr': 'Serbe',
        'ss': 'Swati',
        'st': 'Sotho du Sud',
        'su': 'Soundanais',
        'sv': 'Suédois',
        'sw': 'Swahili',
        'ta': 'Tamoul',
        'te': 'Télougou',
        'tg': 'Tadjik',
        'th': 'Thaï',
        'ti': 'Tigrigna',
        'tk': 'Turkmène',
        'tl': 'Tagalog',
        'tn': 'Tswana',
        'to': 'Tongien',
        'tr': 'Turc',
        'ts': 'Tsonga',
        'tt': 'Tatar',
        'tw': 'Twi',
        'ty': 'Tahitien',
        'ug': 'Ouïghour',
        'uk': 'Ukrainien',
        'ur': 'Ourdou',
        'uz': 'Ouzbek',
        've': 'Venda',
        'vi': 'Vietnamien',
        'vo': 'Volapük',
        'wa': 'Wallon',
        'wo': 'Wolof',
        'xh': 'Xhosa',
        'yi': 'Yiddish',
        'yo': 'Yoruba',
        'za': 'Zhuang',
        'zh': 'Chinois',
        'zu': 'Zoulou',
        'mx': 'Mexicain',
        'fin': 'Finlandais',
    },
    SHOOTINGSIDE: {
        FACE: "De Face",
        THREEQUARTERLEFT: "De 3/4 à gauche",
        THREEQUARTERRIGHT: "De 3/4 à droite",
        FACEINCO: "Face légale INCO"
    },
    STATS: {
        EXE: "Execution",
        REPRO: "Reprographie",
        VERSION: "Version",
        VALUES: "Valeurs",
        REF_VALUES: "Valeurs de référence",
        KPI: "KPI",
        GLOBAL_STATS: "Chiffres clefs",
        CUSTOMER_PROD: "Production client",
        NBR_REF: "(Nombre de réfs)",
        SELECT_NOT_ENABLED: "Désactiver la sélection",
        SELECT_ENABLED: "Sélectionner",
        DASHBOARD: "Tableau de projets",
        TE0: "Te0",
        TE1: "Te1",
        TE2: "Te2",
        TEN: "Ten",
        TENPLUS1: "Ten+1",
        TP0: "Tp0",
        TP1: "Tp1",
        TP2: "Tp2",
        TPN: "Tpn",
        TPNPLUS1: "Tpn+1",
        NB_AR_INTERN: "Nb A/R interne",
        NB_AR_EXTERN: "Nb A/R externe",
        INFOGRAPHISTES: "Infographistes",
        REACT_INTERN_R: "Réactivité Diadem R+",
        REACT_CLIENT: "Réactivité Client",
        NB_RELECTURES_CLIENT: "Nombre de relectures client",
        PROJECT: "Ce projet",
        CHILD_JOB_PARENT: "Projet enfants du même projet Parent",
        MOY_ALL_PROJECTS: "Moyenne tous projets",
        MOY_R_CUSTOMER: "Temps moyen relecture client",
        MOY_TIME_R_CUSTOMER: "Temps moyen de remise R au client",
        MOY_TIME_RN: "Tps moyen de production entre Rn et Rn+1",
        SELECT_CUSTOMER: "Sélectionner un client pour afficher les données",
        TOOLTIP: {
            TE0: "Date réception Brief Client ",
            TE1: "Date de mise en production",
            TE2: "Date de validation de la relecture par le CPP",
            TEN: "Date de retour du Client sur la Rn",
            TENPLUS1: "Date de remise de la Rn+1 au Client ",
            TP0: "Date à laquelle l’exe a été validée – ou date de mise en production Repro",
            TP1: "Date de remise de la Rp1",
            TP2: "Date de validation de la relecture par le CPP",
            TPN: "Date de retour du Client sur la Rpn",
            TPNPLUS1: "Date de remise de la Rpn+1 au Client",
            KPI: "Indicateur clé de performance",
            REF_VALUES: "Pour tous les projets"
        }
    },
    INFORMATION_TO_COME: "Information à venir",
};
