import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { IProofReading, FlowType, Proofreading, ApprovalAssessments } from '../models/proofreading.model';
import { LivecoAPIService } from './liveco-api.service';
import * as _ from 'lodash';
import { ExternStateName, IState, IStateCondition, InternStateName, StateConditionOptions } from '../models/states.model';
import { GeneralError } from '@feathersjs/errors';
import { StateMachineService } from './machine-state.service';
import { IPRHistoryItem } from '../models/proofreading-history.model';
import { ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { ConfirmDialogComponent } from '../shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from './project.service';
import { WarnProdFlowDialogComponent } from '../shared/components/dialog/warn-prod-flux/warn-prod-flux.component';
import { AlertService } from './alert.service';
import { ProofreadingHistoryService, IHistoriesHandler, } from './proofreading-history.service';
import { ChildProject, IChildProject } from '../models/project.model';
import { ProductionTextsService } from './production-texts.service';
import { Id } from '../models/base.model';


@Injectable({
    providedIn: 'root',
})
export class ProofreadingService extends ResolverService<Proofreading> implements OnDestroy {
    path: string = 'proofreadings';
    model: new (any: any) => Proofreading = Proofreading;

    protected _currentHistory: BehaviorSubject<IHistoriesHandler | undefined> = new BehaviorSubject<IHistoriesHandler | undefined>(undefined);
    get currentHistory() {
        return this._currentHistory.value;
    }
    get currentHistory$() {
        return this._currentHistory;
    }

    public view_links = {
        extern: new BehaviorSubject<string | null>(null),
        intern: new BehaviorSubject<string | null>(null)
    };

    protected subscription: Subscription = new Subscription();

    constructor(
        protected feathers: KFeathers,
        protected dialog: MatDialog,
        protected s_loader: LoaderService,
        protected s_liveco: LivecoAPIService,
        protected s_sm: StateMachineService,
        protected s_project: ProjectService,
        protected s_alert: AlertService,
        protected s_proofreadingHistory: ProofreadingHistoryService,
        protected s_productionTexts: ProductionTextsService,
    ) {
        super(feathers);
        this.subscription.add(
            merge(this.s_project.current$, this.s_project.childSubject).subscribe(async (project: ChildProject | undefined | null) => {
                if (project && project._id) {
                    await this.find({ 'project.ID': project._id });
                }
            }
            ));

        this.subscription.add(this.current$.subscribe(this.updateHistory.bind(this)));
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // #region STATE-MACHINE

    async updateHistory() {
        if (this.current) {
            this._currentHistory.next(await this.s_proofreadingHistory.getHistories(this.current?._id, this.current?.flow));
        }
    }

    public static getPrefixRelecture(flow: FlowType) {
        switch (flow) {
            case FlowType.REPRO:
                return { short: 'V', long: 'Version' };
            default:
                return { short: 'R', long: 'Relecture' };
        }
    }

    //#region STATE MACHINE

    //#region EXTERN
    async onNextExternState(nextState: ExternStateName) {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }
        const current: IState = this.current.state;
        const res = await this.dialogGuardExtern(current, nextState);
        if (!res) {
            return undefined;
        }

        try {
            return await this.nextExternState(nextState);
        } catch (err: any) {
            this.s_alert.error(err.message);
            return undefined;
        } finally {
            this.s_loader.loading = false;
        }
    }

    async nextExternState(nextState: ExternStateName): Promise<IProofReading> {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }

        // const current: IState = this.current.state;

        const result = await this.patch(this.current._id, { 'state_slug': nextState });
        this.current.patch(result);
        return this.current;
    }


    protected async dialogGuardExtern(current: IState, nextState: ExternStateName) {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }
        switch (nextState) {
            default:
                return await this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        title: `EXTERN.${nextState.toUpperCase()}`,
                        text: `EXTERN.DIALOG.${nextState.toUpperCase()}`,
                        valid: "YES",
                        noConfirm: false
                    }
                }).afterClosed().toPromise();
        }
    }
    //#endregion

    //#region INTERN
    async onNextInternState(nextState: InternStateName, assessmentIntern?: boolean) {
        if (!this.current || !this.current.intern) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }
        const current: IState = this.current.intern.currentState;
        const res = await this.dialogGuard(current, nextState, assessmentIntern);
        if (!res) {
            return undefined;
        }

        try {
            return await this.nextInternState(nextState, assessmentIntern);
        } catch (err: any) {
            this.s_alert.error(err.message);
            return undefined;
        } finally {
            this.s_loader.loading = false;
        }
    }

    async onPreviousInternState(previousState: InternStateName) {
        if (!this.current || !this.current.intern) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }
        const current: IState = this.current.intern.currentState;
        const res = await this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: `INTERN.${current?.payload?.text_slug?.toUpperCase() || current.slug?.toUpperCase() || ""}`,
                text: "INTERN.REJECT.DOCUMENT",
                valid: "YES",
            }
        }).afterClosed().toPromise();
        if (!res) {
            return undefined;
        }
        try {
            return await this.previousInternState(previousState);
        } catch (err: any) {
            this.s_alert.error(err.message);
            return undefined;
        } finally {
            this.s_loader.loading = false;
        }
    }

    protected async dialogGuard(current: IState, nextState: InternStateName, assessmentIntern?: boolean) {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }

        const next_state = this.s_sm.entities.find(e => e.slug === nextState && e.type === 'intern' && e.flow.includes(this.current!.flow));
        nextState = next_state?.payload?.text_slug || nextState;

        switch (nextState) {
            case InternStateName.CHECK_AUTO_CTRL:
                // DONT WARN IF NOT FIRST INTERN VERSION
                if (this.current.intern?.viewer.version) {
                    return true;
                }
                // WARN ABOUT SOME REQUISITE BEFORE PROD FLUX
                return await this.dialog.open(WarnProdFlowDialogComponent, {
                    data: {
                        title: `INTERN.WARN_PROD.TITLE`,
                        text: `INTERN.WARN_PROD.DESC`,
                        relecture: this.current.relecture,
                        valid: "YES",
                        options: [this.current.flow, 'INFO_CARTOUCHE']
                    }
                }).afterClosed().toPromise();
            case InternStateName.CONTROL_DELIVERY_VALIDATED:
                // WARN ABOUT SOME REQUISITE BEFORE DELIVERY FLUX
                return await this.dialog.open(WarnProdFlowDialogComponent, {
                    data: {
                        title: `INTERN.WARN_DELIVERY.TITLE`,
                        text: `INTERN.WARN_DELIVERY.DESC`,
                        relecture: this.current.relecture,
                        valid: "YES",
                        options: await this.getDeliveryRequiredTasks()
                    }
                }).afterClosed().toPromise();
            default:
                return await this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        title: `INTERN.${current?.payload?.text_slug?.toUpperCase() || current.slug?.toUpperCase() || ""}`,
                        payload: current.payload,
                        text: _.isUndefined(assessmentIntern)
                            ? `INTERN.APPROVE.${nextState ? nextState.toUpperCase() : ""}`
                            : `INTERN.REJECT.${nextState ? nextState.toUpperCase() : ""}`,
                        valid: "YES",
                        noConfirm: false
                    }
                }).afterClosed().toPromise();
        }
    }

    async nextInternState(nextState: InternStateName, assessmentIntern?: boolean): Promise<IProofReading> {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }

        // const current: IState = this.current.intern.currentState;

        const result = await this.patch(this.current._id, { 'intern.state_slug': nextState, 'intern.assessment': assessmentIntern });
        if (this.current)
            this.current.patch(result);
        return this.current;
    }

    async previousInternState(previousState: InternStateName): Promise<IProofReading> {
        if (!this.current) {
            throw new GeneralError('[CLIENT][PR SERVICE] Current proofreading is not defined');
        }

        const result = await this.patch(this.current._id, { 'intern.state_slug': previousState });
        if (this.current)
            this.current.patch(result);
        return this.current;
    }
    //#endregion

    previousCondition(current: IState, previous: string, typesUser: string[]): boolean | undefined {
        if (!this.current) {
            return undefined;
        }
        if (current?.previous) {
            const p: any = current?.previous[previous];
            return this.verifyCondition(p, typesUser);
        } else {
            return undefined;
        }

    }

    nextCondition(current: IState, next: string, typesUser: string[]): boolean | undefined {
        console.log('nextCondition', current, next, typesUser);
        if (!this.current) {
            return undefined;
        }
        if (current?.next) {
            const n = current?.next[next];
            return this.verifyCondition(n, typesUser);
        } else {
            return undefined;
        }
    }

    protected verifyCondition(n: IStateCondition, typesUser: string[]) {
        const type_user_verdict = n?.conditions ? n.conditions.some((condition: string) => typesUser.includes(condition)) : undefined;
        let verdict = type_user_verdict;
        const imperative_conditions = n?.conditions.filter(c => c.startsWith('&'));
        verdict = verdict && imperative_conditions.every((condition: string) => this.assert_imperative_condition(condition, typesUser));
        return verdict;
    }

    protected assert_imperative_condition(c: string, user_types: string[]) {
        const condition = c.replace('&', '');
        if (user_types.includes(condition)) {
            return true;
        }
        switch (condition) {
            case StateConditionOptions.EXTERN_APPROVED:
                return this.current?.state?.slug === ExternStateName.APPROVED;
            case StateConditionOptions.FICHE_TEXT:
                return !!this.s_productionTexts.current;
            default:
                return false;
        }
    }

    //#endregion

    //Flux PAUSE
    async approvalClient(assessment: ApprovalAssessments, usersID?: Id[]) {
        try {
            await this.patchCurrent({ state_slug: ExternStateName.FEEDBACK_CLIENT, state_args: { assessment, usersID } }).toPromise();
            console.log('approvalClient', assessment, usersID, this.current);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            this.s_loader.loading = false;
        }
    }


    async approvalInfo(assessment: ApprovalAssessments, usersID?: Id[]) {
        try {
            await this.patchCurrent({ state_slug: ExternStateName.FEEDBACK_INFO, state_args: { assessment, usersID } }).toPromise();
            console.log('approvalInfo', assessment, usersID, this.current);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            this.s_loader.loading = false;
        }
    }

    async approval(assessment: ApprovalAssessments) {
        const state_slug = assessment === ApprovalAssessments.REJECT ? ExternStateName.REJECTED : ExternStateName.APPROVED;
        try {
            // // SAVE CURRENT TEXT ON APPROVE CPP
            // if (this.s_productionTexts.current) {
            //     await this.s_productionTexts.patchCurrent().toPromise();
            // }
            await this.patchCurrent({ state_slug, state_args: { assessment } }).toPromise();
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            this.s_loader.loading = false;
        }
    }

    onApproval(reply: any) {
        if (reply)
            window.location.reload();

    }

    //#endregion


    async getDeliveryRequiredTasks() {
        if (!this.current) {
            return [];
        }

        const project: IChildProject = await this.s_project.get(this.current.project.ID);
        const marque = (await this.s_liveco.find({ label: project.custom.general_info.marque }, '/marques')).result[0];

        let tasks = [];

        switch (this.current.flow) {
            case FlowType.REPRO:
                tasks = ['REPRO_FILES', 'IMAGE'];

                if (marque?.flags && marque?.flags['superdev']) {
                    tasks.push(...['PSD', 'PDF_BD']);
                }

                break;
            default:
                // tasks = ['EXE_FILES', 'PSD', 'VECT_PDF'];
                // if (marque?.flags && marque?.flags['superdev']) {
                //     tasks = ['EXE_FILES', 'PSD', 'VECT_ILLUSTRATOR'];
                // }

                tasks = ['EXE_FILES', 'NOT_VECT_PDF', 'VECT_PDF', 'IMAGE'];

                if (marque?.flags) {
                    if (marque?.flags['deliveryVectIllustrator']) {
                        tasks.push('VECT_ILLUSTRATOR');
                    }

                    if (marque?.flags['deliveryFonts']) {
                        tasks.push('FONTS');
                    }

                    if (marque?.flags['deliveryPrepa3d']) {
                        tasks.push('PREPA_3D');
                    }

                    if (marque?.flags['deliveryTemplate']) {
                        tasks.push('DELIVERY_TEMPLATE');
                    }

                    if (marque?.flags['deliveryFTP']) {
                        tasks.push('FTP_DEPOSIT');
                    }

                    if (marque?.flags['deliveryDAM']) {
                        tasks.push('DAM_DELIVERY');
                    }
                }

                if (project?.custom?.exe?.deliverables && _.isArray(project.custom.exe.deliverables)) {
                    tasks = project.custom.exe.deliverables;
                }

                break;
        }

        // TEST TEAM CREATIF 
        if (project.custom.general.id_jobteam && project.custom.general_info.agency !== 'SHORTLINKS') {
            tasks.push('DAM_DELIVERY');
        }

        // if(project.custom.general_info.)


        return tasks;
    }




    async getHistoryItems(proofreadingID: string, type?: 'intern' | 'extern' | 'approval', flow?: FlowType) {
        const historyItems: IPRHistoryItem[] = (await this.s_liveco.find({ proofreadingID, type, flow }, '/proofreading-history')).result;
        if (!historyItems.length) {
            return [];
        }
        return historyItems;
    }
}
