import * as _ from "lodash";
import { FlowType } from "../../proofreading.model";
import { ProjectService } from "src/app/services/project.service";
import { RequestType, Request, IRelectureSubRequest, IModificationSubRequest, ISourceProjectSubRequest } from "../request.model";


export class CorrectionRequest extends Request {
    override type: RequestType = RequestType.TEXT_CORRECTION;
    override flow = FlowType.EZD_EXE;

    override relecture: IRelectureSubRequest = {
        members: [],
    };
    override source_project: ISourceProjectSubRequest = {
        identifier: '',
    };

    printer: IModificationSubRequest = {
        info_validity: true,
        comment: '',
    };
    seq_colors: IModificationSubRequest = {
        info_validity: true,
        comment: '',
    };

    override options: any = {
        ...this.options,
        rvf: {
            jobType: "execution",
            dateIncrement: 1
        },
    };

    constructor(data: Partial<CorrectionRequest>) {
        super(data);
        this.init(data);
    }

    onProjectCreation(service: ProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                this.selectServiceInProject(service);
                this.insertRequestMemberInProjectMember(service);
                this.insertCommPrinterInProject(service);
                this.insertCommSeqColorInProject(service);
                break;
            case "submit":
                break;
        }
        super.onProjectCreation(service, lifecycle);
    }

    // private insertCommCustomerInProject(service: ProjectService) {
    //     // Set request comm customer, name and reset service
    //     _.set(service.childData || {}, 'custom.exe.commentaire_cpp', this.finalization?.comment);
    // }
    private selectServiceInProject(service: ProjectService) {
        _.set(service.childData || {}, 'custom.general.service_exe', true);
        _.set(service.childData || {}, 'custom.general.service_hd', false);
        _.set(service.childData || {}, 'custom.general.service_repro', false);
        _.set(service.childData || {}, 'custom.general.service_other', false);

        // Remove objects from other services
        _.set(service.childData || {}, 'custom.hd', undefined);
        _.set(service.childData || {}, 'custom.repro', undefined);
        _.set(service.childData || {}, 'custom.other', undefined);
    }

    private insertRequestMemberInProjectMember(service: ProjectService) {
        // Set request member in project member
        _.set(service.childData, 'custom.members', this.relecture?.members);
        // flag the easydiadem service so member edit is disabled
        service.s_requests.setFlag("project-members-creation-locked", true);
    }

    private insertCommPrinterInProject(service: ProjectService) {
        // Set request comm customer, name and reset service
        if (!this.printer?.info_validity)
            _.set(service.childData || {}, 'custom.general.comm_printer', this.printer?.comment);
    }

    private insertCommSeqColorInProject(service: ProjectService) {
        // Set request comm customer, name and reset service
        if (!this.printer?.info_validity)
            _.set(service.childData || {}, 'custom.colors.comm_seq_colors', this.seq_colors?.comment);
    }
}
