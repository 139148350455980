import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { AlertService } from "src/app/services/alert.service";
import { AuthService } from "src/app/services/auth.service";
import { LoaderService } from "src/app/services/loader.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { environment } from 'src/environments/environment';


// // @ts-ignore
// const api = require("@modules/cf-api.js").api;
// const api_async = require("@modules/cf-api.js").api_async;

@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./nav.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {

    public appRoutes = {
        home: "/",
        kiosk: environment.cfURL,
        printers: "/admin/printers-table",
        colors: "/admin/colorbooks",
        users: "/admin/users-table",
        stats: "/stats",
        account: "/my-account",
        'ed-dashboard': "/easydiadem/dashboard",
    };

    @Input() userFullName: string;

    @ViewChild("searchInput") searchInput: ElementRef | null = null;
    @ViewChild("searchIcon") searchIcon: MatIcon | null = null;

    isActive: boolean;
    sampleModel: string = "";
    cfURL: string = environment.cfURL;

    get notifs() {
        return this.s_notifications.entities;
    }

    constructor(
        protected route: ActivatedRoute,
        public router: Router,
        protected alert: AlertService,
        protected cd: ChangeDetectorRef,
        protected loaderService: LoaderService,
        protected auth: AuthService,
        protected s_notifications: NotificationsService
    ) { }

    async ngOnInit() {
        await this.s_notifications.init();
    }

    logout() {
        this.auth.logout();
    }

    userManual() {
        window.open("/assets/Manuel_Utilisation_Liveco_4.pdf", "_blank");
    }

    isCPP(): boolean {
        return this.auth.isCPP();
    }

    isFab(): boolean {
        return this.auth.isFab();
    }

    isOperator(): boolean {
        return this.auth.isOperator();
    }

    isClient(): boolean {
        return this.auth.isClient();
    }

    searchOnMouseEnter(): void {
        if (this.searchInput) {
            this.searchInput.nativeElement.classList.add("hovered");
        }
        if (this.searchIcon) {
            this.searchIcon._elementRef.nativeElement.classList.add("hovered");
        }
    }

    searchOnMouseLeave(): void {
        if (this.searchInput) {
            this.searchInput.nativeElement.classList.remove("hovered");
        }
        if (this.searchIcon) {
            this.searchIcon._elementRef.nativeElement.classList.remove("hovered");
        }
    }

    updateDisplay(): void {
        if (this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value && this.searchInput.nativeElement.value.length > 0) {
            this.searchInput.nativeElement.classList.add("hasValue");
        } else {
            this.searchInput?.nativeElement.classList.remove("hasValue");
        }
    }

    makeSearch(): void {
        if (this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value && _.isString(this.searchInput.nativeElement.value) && this.searchInput.nativeElement.value.length > 0) {
            const isEasyDiadem = this.router.url.includes('/easydiadem/');

            let redirectUrl = '/';

            if (this.isCPP() && isEasyDiadem) {
                redirectUrl = '/easydiadem/dashboard';
            }

            // localStorage.setItem('dashboard-search', encodeURIComponent(this.searchInput.nativeElement.value));

            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([redirectUrl], { queryParams: { identifier: this.searchInput.nativeElement.value } });
        }
    }

    // hasLimitedAccess(): boolean {
    //     return this.auth.hasCustomerLimitedAccess;
    // }

    isCurrentUrl(url: string): boolean {
        return this.router.url === url;
    }
}
